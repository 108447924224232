

  <div class="contenedor_presentacion row mb-5">
    <div class="navegacion mb-5">
      <p><a routerLink="/home">Home</a> >  Presentacion   </p>
    </div>
    <div class="contenedor_presentacion2 ">
      <div class="container_texto" >
        <h3 class="mb-3">Percy Alberto Ramos Puelles</h3>
        <div class="historia-img historia-img2">
          <img src="assets/img/Escudo.jpg" alt="">
        </div>
      <p>
        PERIODO: 2023 - 2026 
        <br>
          Nacio el 16 de octubre de 1958 (64 años) en la ciudad de  
          La Libertad. Hizo sus estudios primarios en el Colegio José Encinas 271 del Distrito
          de Víctor Larco Herrera de Trujillo, y los secundarios en el Colegio José Sánchez Carrión
          de Trujillo. Ha realizado diversas obras emblemáticas en la Provincia de Lambayeque.
      </p>
      </div>
      <div class="container_img">
        <img src="assets/img/funcionarios/percy_ramos2.png" alt="">
      </div>
    </div> 
  <!-- <div class="presentacion">
      <h1 class="fw-bold">Presentación </h1>
      <div class="grid">
        <div class="item1">
          <img src="assets/img/funcionarios/percy_ramos2.png" alt="Imagen de la persona">
        </div>
        <div class="item2">
          <p>
            Los primeros alcaldes de la ciudad de Lambayeque fueron personajes notables a quienes se les delegaba esta responsabilidad, y sus primeros entes representativos eran los cabildos, según podemos conocer de las crónicas coloniales.
            <br>
            Posteriormente, se crearon las Juntas de Notables, quienes ejercían la labor de concejales o fiscalizadores y nombraban a los encargados de recoger los tributos, los llamados síndicos.
            <br>
            Como institución, la Municipalidad se formó a inicios del siglo XX y funcionó en pequeños ambientes improvisados hasta el año 1940, cuando se adquirió el terreno propio donde hoy funciona.
            <br>
            El edificio municipal se empezó a construir en el año 1945, siendo alcalde el Sr. Arturo Leandro Pastor, y se terminó el año 1945 en la gestión del Sr. Miguel Oneto García.
            Su ejecución estuvo a cargo del Sr. Víctor Mora Flores, quien plasmó su estilo republicano y detalles hermosos en la fachada, los cuales son muy admirados por los turistas que visitan esta ciudad.
            <br>
            A través de los años, se ha continuado con la ampliación de áreas del local municipal para una mejor atención al público:
            <br>
            En 1981, se construyó el local para la Biblioteca “Emiliano Niño Pastor” en la gestión del alcalde don Ángel Gonzáles Castro.
            En el 2001, se construyeron las oficinas de la Gerencia de Infraestructura y Urbanismo, la Subgerencia de Obras y la Gerencia de Administración Tributaria.
            En el 2002, se construyó un nivel para el servicio de la Gerencia de Desarrollo Económico y la OMAPED, en la gestión del alcalde Ing. Ricardo Casimiro Velezmoro Ruiz.
            En el 2007, se implementó la Cabina Pública Municipal en el local de la Biblioteca Municipal.
            En el 2008, se construyó el tercer piso para la Gerencia de Administración Financiera, Subgerencia de Tesorería y Subgerencia de Contabilidad. Además, se creó la oficina de Tramifácil y se construyó el local descentralizado del Instituto Vial Provincial, durante la gestión del alcalde C.P.C. Percy Alberto Ramos Puelles. 
          </p>
        </div>
      </div>
    </div>  -->
    
    
    <section class="col-lg-8 mt-5">
      <div class="p-4 col-p-1 mb-4 bg-light rounded-3">
        <div class="container-fluid  py-5  historia">
          <h1 class="fw-bold">Historia</h1>
          <div class="historia-img">
            <img src="assets/img/Escudo.jpg" alt="">
          </div>
          <p class="col-md-11 p-2  ">
            Los primeros alcaldes de la ciudad de Lambayeque fueron personajes notables a quienes se les delegaba esta responsabilidad, y sus primeros entes representativos eran los cabildos, según podemos conocer de las crónicas coloniales.
            <br>
            Posteriormente, se crearon las Juntas de Notables, quienes ejercían la labor de concejales o fiscalizadores y nombraban a los encargados de recoger los tributos, los llamados síndicos.
            <br>
            Como institución, la Municipalidad se formó a inicios del siglo XX y funcionó en pequeños ambientes improvisados hasta el año 1940, cuando se adquirió el terreno propio donde hoy funciona.
            <br>
            El edificio municipal se empezó a construir en el año 1945, siendo alcalde el Sr. Arturo Leandro Pastor, y se terminó el año 1945 en la gestión del Sr. Miguel Oneto García.
            Su ejecución estuvo a cargo del Sr. Víctor Mora Flores, quien plasmó su estilo republicano y detalles hermosos en la fachada, los cuales son muy admirados por los turistas que visitan esta ciudad.
            <br>
            A través de los años, se ha continuado con la ampliación de áreas del local municipal para una mejor atención al público:
            <br>
            En 1981, se construyó el local para la Biblioteca “Emiliano Niño Pastor” en la gestión del alcalde don Ángel Gonzáles Castro.
            En el 2001, se construyeron las oficinas de la Gerencia de Infraestructura y Urbanismo, la Subgerencia de Obras y la Gerencia de Administración Tributaria.
            En el 2002, se construyó un nivel para el servicio de la Gerencia de Desarrollo Económico y la OMAPED, en la gestión del alcalde Ing. Ricardo Casimiro Velezmoro Ruiz.
            En el 2007, se implementó la Cabina Pública Municipal en el local de la Biblioteca Municipal.
            En el 2008, se construyó el tercer piso para la Gerencia de Administración Financiera, Subgerencia de Tesorería y Subgerencia de Contabilidad. Además, se creó la oficina de Tramifácil y se construyó el local descentralizado del Instituto Vial Provincial, durante la gestión del alcalde C.P.C. Percy Alberto Ramos Puelles. 
          </p>
          <P>Último cambio  02 abril 2023</P>
          <!-- <button class="btn btn-primary btn-lg" type="button">Example button</button> -->
        </div>
      </div>
  
      <div class="row align-items-md-stretch mision-vision" >
        <div class="col-md-6 ps-2 mb-3">
          <div class="h-100 p-4   bg-light rounded-3 border ">
            <!--  text-bg-dark  -->
            <h2>Misión</h2>
            <p>
              "Somos una Municipalidad, que brinda servicios públicos de calidad a los ciudadanos y ciudadanas, 
              con eficacia y efeiciencia; desarrollando actividades orientadas a la inclusión social y equidad 
              de género; que promueve y fomenta el desarrollo económico, turístico y socio cultural de las personas;
               conjuntamente con las organizaciones de la sociedad civil e instituciones públicas y privadas de la 
               Provincia de Lambayeque; fortaleciendo nuestra identidad cultural, y condiciones ambientales, para mejorar 
              la calidad de vida de los vecinos y vecinas, garantizando su tranquilidad, seguridad y
              bienestar"
            </p>
            <!-- <button class="btn btn-outline-light" type="button">Example button</button> -->
          </div>
        </div>
        <div class="col-md-6 ps-2 ">
          <div class="h-100 p-4  bg-light border rounded-3">
            <h2>Visión</h2>
            <p class="mt-2"> 
              "Seremos una Municipalidad lider que brinda servicios de calidad a los vecinos y vecinas; 
              con una gestión organizada, eficaz, inclusiva, transparente, participativa y con equidad de 
              género, promoviendo la identidad cultural, el compromiso por la gestión de riesgo de desastres 
              y la gestión ambiental; su personal es solidario, proactivo y competente, posicionando a Lambayeque
               como una provincia, moderna, ordenada, segura, turística, saludable,
               con una economía fluida; promoviendo el desarrollo integral de la ciudadania Lambayecana".
              </p>
            <!-- <button class="btn btn-outline-secondary" type="button">Example button</button> -->
          </div>
        </div>
      </div>
    </section>
    <div class="p-4 mt-5 col-lg-4">
      <div class="mt-5">
        <h4 class="fst-italic">Lo más visto</h4>
      <ol class="list-unstyled mb-0 ps-2">
        
        
        <li><a href="https://www.gob.pe/23228-solicitar-certificado-domiciliario?child=15399">> Solicitar certificado domiciliario a la Municipalidad Provincial de Lambayeque</a></li>
        <li><a href="https://www.gob.pe/23228-solicitar-certificado-domiciliario?child=15399">> Solicitar certificado domiciliario a la Municipalidad Provincial de Lambayeque</a></li>
        <li><a href="https://www.gob.pe/institucion/munilambayeque/pages/15404-obtener-certificado-de-salubridad-para-locales-comerciales-en-lambayeque">> Obtener certificado de salubridad para locales comerciales. </a></li>
        <li><a href="https://www.gob.pe/20414-obtener-copia-certificada-de-una-partida-o-acta-de-nacimiento-matrimonio-o-defuncion?child=16363">> Solicitar copia certificada de partida de nacimiento, matrimonio o defunción </a></li>
        <li><a href="https://www.gob.pe/20399-solicitar-acceso-a-la-informacion-publica?child=15479">> Solicitar información pública a la Municipalidad Provincial de Lambayeque</a></li>
        <li><a href="https://reclamos.servicios.gob.pe/?institution_id=284" target="_blank">> Libro de reclamaciones</a></li>
        <li><a routerLink="/gobierno/consultar-tramite">> Seguimiento de trámites</a></li>
        <li><a routerLink="/gobierno/portal-de-transparencia">> Realizar trámites</a></li>
        <li><a routerLink="/gobierno/TUPA">> TUPA</a></li>
        <li><a routerLink="/gobierno/informacion-publica">> Acceso a la información pública</a></li>
      </ol>
      </div>
      
    </div>

    <!-- div class="enlaces-externos">
      <h2></h2>
      <ul>
        <li><a href="https://www.google.com">Google</a></li>
        <li><a href="https://www.facebook.com">Facebook</a></li>
        <li><a href="https://www.twitter.com">Twitter</a></li>
      </ul>
    </div> -->

    
  </div>




  