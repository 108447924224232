<div class="flexslider">
    <ul class="slides">
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
       <!--  <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
        </div> -->
        
        <div class="carousel-inner">
          
          <div class="carousel-item active">
            <img src="assets/img/comisaria.png" class="d-block w-100" alt="...">
            <!-- <div class="carousel-caption d-none d-md-block">
            </div> -->
          </div>
          <div class="carousel-item">
            <img src="assets/img/centro_monitoreo.png" class="d-block w-100" alt="...">
            <!-- <div class="carousel-caption d-none d-md-block">
              <h5>Third slide label</h5>
            </div> -->
          </div>
          <div class="carousel-item">
            <img src="assets/img/defensa_civil.png" class="d-block w-100" alt="...">
            <!-- <div class="carousel-caption d-none d-md-block">
              <h5>Munil</h5>
            </div -->
          </div>
          <div class="carousel-item">
            <img src="assets/img/Orientacion_ciudadano.png" class="d-block w-100" alt="...">
            <!-- <div class="carousel-caption d-none d-md-block">
              <h5>RRR slide label</h5>
            </div> -->
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span class="carousel-control-prev-icon bg-danger" aria-hidden="true"></span>
          <span class="visually-hidden ">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span class="carousel-control-next-icon bg-danger" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </ul>
  </div>
   
  
