import { Component, Output, Renderer2, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
//import { PageSearchComponent } from '../page-search/page-search.component';
import { trigger,transition,style,animate, state } from '@angular/animations';

//import {SearchServiceService} from '../servicios/search-service.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [
    /* trigger('nombreAnimacion', [

      state('activate',style({
        display: 'block',
        background :'red',
        transform: 'translateY(100%)' 
      })),
      state('desactivate',style({
        display: 'none',
        background :'blue',
        transform: 'translateY(-100%)' 
      })),
      transition('activate => desactivate', 
        animate('0.5s ease-in')
      ),
      transition('desactivate => activate', 
        animate('0.5s ease-out')
      )
    ]) */
  ]
})
export class MenuComponent {

  valorBusquedad: String=""; //Variable de busquedad;
  @Output() searchResults = new EventEmitter<any[]>();  

  /* state: String= 'desactivate'; */

  

  private btnMenu: any;
  menubar :any;
  btn_menu :number;
  
  constructor(private renderer: Renderer2, private  router:Router) {
      this.btn_menu = 1;
  }

  ngOnInit() {
   setTimeout(() => {
    this.toggleMenuClass();
  });
   
  }

  /*     Metodos para la opcion buscar */
  getBusquedad(){
          //console.log("valor bus 1: "+this.valorBusquedad);
          if(this.valorBusquedad != ""){
            this.router.navigate([`/page-search/${this.valorBusquedad}`])
            this.valorBusquedad = ""; //limpio el campo
            //console.log("valor bus 2: "+this.valorBusquedad);/*  */
          }else{
            alert("Ingrese un valor de busqueda");
          }
  }

  /* -------------- end methods ------------  */

  /*  METHODS FOR MENU */
 /*  ngAfterViewInit() {
  } */

  toggleMenuClass() {
    //console.log(this.state)
    //this.state = (this.state) == 'desactivate'?'activate' :'desactivate' ;
    setTimeout(() => {
      this.btn_menu = (this.btn_menu)==1? 0:1;
    });   
  }

  /* mostratPageServicios(parametro:String){

  } */

  irServicios(id:number){

      this.router.navigate([`/page-servicios/`+id])
  }










  

}
