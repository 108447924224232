import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DocumentoServiceService } from '../servicios/documento-service.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-normas-legales',
  templateUrl: './normas-legales.component.html',
  styleUrls: ['./normas-legales.component.css']
})
export class NormasLegalesComponent implements OnInit {

  tipoSelect: string; 
  trimestreSelec: string;
  anioSelec: string;

  /* Dtaatatables */
  dtOptions: DataTables.Settings = {};
  url = "http://localhost:8083/documentos/listar";
  data: any = [];
  filtro: any = [];
  dtTrigger: Subject<any>= new Subject();
  constructor(private httpClient: HttpClient, documentoservice: DocumentoServiceService) {
    this.tipoSelect = "";
    this.anioSelec = "";
    this.trimestreSelec = "";
  }

  ngOnInit(): void {
    this.httpClient.get(this.url)
      .subscribe((data) => {
        this.data = data;
        this.filtro = data;
        this.dtTrigger.next(this.filtro);
    })
    this.dtOptions = {
      columns: [
        { title: "Nombre", data: "nombre_doc" },
        { title: "tipo", data: "tipo_doc" },
        { title: "descripcion", data: "descripcion_doc" },
        { title: "fecha", data: "fecha_doc" },
        { title: "link", data: "link" }
      ],
      pagingType: 'full_numbers'
    };
  }

  onChangeTrimestre() {
    console.log("valor trimeres seleccionado: " + this.trimestreSelec); // guardar el valor seleccionado en la variable
    if (this.trimestreSelec == "") {
      this.tipoSelect = "";
    }
  }

  onOptionAnioChange() {
    console.log("valor anio seleccionado: " + this.anioSelec); // guardar el valor seleccionado en la variable
    if (this.anioSelec == "") {
      this.trimestreSelec = "";
      this.tipoSelect = "";

      // llamo a la funcion:
    } 
  }


 /*  getfilterAnio(fecha: String): any[] {
    return this.filtro.filter((item: any) => {
      if (item.fecha_doc == null) {
        console.log("nuloss")
        return false;
      }
      console.log("fecha:parametro:  ", item.fecha_doc.substr(0, 4), "--", fecha)
      return item.fecha_doc.substr(0, 4) == fecha
    }); */





}
