<div class="container height_container mt-4">
    <h1>Convocatorias</h1>
    <div class="container_tabla">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="announceSortChange($event)"
     >
     <!--  matSort (matSortChange)="announceSortChange($event)" mat-sort-header -->
    <!--  <ng-container matColumnDef="idconvocatorias">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by nombre_doc" >Id</th>
        <td mat-cell *matCellDef="let element">
          {{ element.idconvocatorias}}
        </td>
      </ng-container> -->
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by nombre_doc" >Nombre</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nombre.replaceAll('Ã','Á')
            .replaceAll('Ã‰', 'É')
            .replaceAll("Ã�","Í")
            .replaceAll('Ã“', 'Ó') 
            .replaceAll("Ãš","Ú")
            .replaceAll('Ã‘','Ñ')
            .replaceAll("NÂº ","Nº")
            .replaceAll("Â°","º")
            }} 
        </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="periodo">
        <th  class="w-max" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by periodo"> Periodo</th>
        <td  class="w-max" mat-cell *matCellDef="let element">{{element.periodo}}</td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="estado">
        <th class="w-max" mat-header-cell *matHeaderCellDef mat-sort-header >Estado</th>
        <td class="w-max" mat-cell *matCellDef="let element" >
            <p *ngIf="element.estado == 'inactivo' "> Finalizado</p>
            <p *ngIf="element.estado != 'inactivo' "> En proceso</p>
        </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="link">
        <th class="w-max" mat-header-cell *matHeaderCellDef  >Ver</th>
        <td class="w-max" mat-cell *matCellDef="let element">
          <a href="http://www.munilambayeque.gob.pe/presentacion{{element.link}}" target="_blank" ><img src="assets/img/pdf-ico.png" alt=""></a>
        </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
    </div>
  </div>
  
  
  
  
