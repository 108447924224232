import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { __values } from 'tslib';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environments';

const apiUrl = `${environment.other.apiUrl}`;
const headers = new HttpHeaders().set('Content-Type', 'application/json');
const options = { headers };
@Injectable({
  providedIn: 'root'
})
export class SearchServiceService {

  //private urlNoticiasRequestMapping = `${apiUrl}noticias/buscarnoticias`;
  private urlNoticiasRequestMapping = `${apiUrl}noticias/buscarnoticias`;
  //private urlNoticiasRequestMapping = 'http://localhost:8083/noticias/buscarnoticias';


  constructor(private http: HttpClient) { }

  /* Method for search */
  private searchResult = new Subject<String>();


  /* Metodo para obtener datos de backend: */
  getBusquedadNoticias(busq: String): Observable<any[]> {
    /* console.log("Busq - servicio : " + busq + "-- URL : " + `${this.urlNoticiasRequestMapping}/${busq}`) */
    return this.http.get<any[]>(`${this.urlNoticiasRequestMapping}/${busq}`,options)
    // http://localhost:8083/noticias/buscarnoticias/

  }

  /* Methods for noticia por id => noticia completa */
  getObtenerNoticia(id: Number):Observable<any>{

    /* Nota los http siempre devuelven un observable */
   /*  console.log("se crea el servicio: id: "+id); */
    return this.http.get<any>(`${this.urlNoticiasRequestMapping}/id/${id}`,options);

  }

}
