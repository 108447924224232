import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentoServiceService } from '../servicios/documento-service.service';
import { DataTableDirective } from 'angular-datatables';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Router } from '@angular/router';

@Component({
  selector: 'app-directivas',
  templateUrl: './directivas.component.html',
  styleUrls: ['./directivas.component.css']
})
export class DirectivasComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })

  dtOptions: DataTables.Settings = {};
  //varaibles necesarias
  url = "http://localhost:8083/documentos/directivas";
  data: any = [];
  /* dtTrigger: Subject<any> = new Subject<any>() */;


  ELEMENT_DATA: Directiva[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  /* Preparamos la tabla  - columns*/
  displayedColumns: string[] = ['nombre_doc', 'descripcion_doc', 'fecha_doc', 'link_doc'];

  //creamo el elemento datasource
  dataSource = new MatTableDataSource<Directiva>(this.ELEMENT_DATA);


  //constructor de la vista
  constructor(private http: HttpClient, private documentoService: DocumentoServiceService,
    private _liveAnnouncer: LiveAnnouncer, private route: Router) {
  }

  ngOnInit(): void {


    this.obtenerDataDirectivas();


  }

  public obtenerDataDirectivas() {
    this.documentoService.getDirectivas()
      .subscribe((data) => {
        this.ELEMENT_DATA = data;
        //console.log("data directivas: ", this.data)
        this.dataSource = new MatTableDataSource<Directiva>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      })
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  openPdf(linkPdf: string) {
    var urlPdf = `http://www.munilambayeque.gob.pe/presentacion/documentos/${linkPdf}`;
    window.open(urlPdf, '_blank');
     
      /*console.log("404:",urlPdf)
    this.http.head(urlPdf).pipe(

      catchError((err) => {
        this.route.navigate(['/error-404']);
        console.error('Error al cargar el archivo:', err);
        return throwError(() => new Error(err));
        //return throwError(() => newError);
      })
    ).subscribe(() => {
      window.open(urlPdf, '_blank');
    }
    ) 
  
    fetch(urlPdf)
    .then(response => {
      if (response.ok) {
        // El recurso existe, abrir enlace en una nueva ventana
        window.open(urlPdf, '_blank');
      } else {
        // El recurso no existe, redirigir a la página de error 404
        this.route.navigate(['/error-404']);
      }
    })
    .catch(error => {
      // Ocurrió un error al enviar la solicitud, redirigir a la página de error 404
      this.route.navigate(['/error-404']);
      console.error('Error al cargar el archivo:', error);
    });*/
  }



}





export interface Directiva {

  id_doc: number;
  nombre_doc: String;
  descripcion_doc: String;
  fecha_doc: String;
  link_doc: String;
}
