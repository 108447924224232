<div class="container_consolidado">
  <div>
    <h4 class="pt-4">Comparativa de estadisticas por tipo y entre años</h4>
  </div>
  <div class="container_inputs">
    <select class="form-select" name="selectionType" id="selectionType" [(ngModel)]="valueType" (click)="clear()">
      <option value="">-- Seleccione --</option>
      <option value="1">Nacimientos</option>
      <option value="2">Defunciones</option>
      <option value="3">Matrimonios</option>
      <option value="4">Divorcios</option>
    </select>
    
    <Label for="cbxTodos"> <input class="" type="checkbox" name="cbxTodos" value="" id="cbxTodos" (click)="cambiarCBX1()" [(ngModel)]="cbxTodo" [disabled]="!valueType"
      />Todos</Label>

    
    <label for="cbxanios"><input class="" type="checkbox" name="cbxanios" id="cbxanios" (click)="cambiarCBX2()" [(ngModel)]="cbxAnio" [disabled]="!valueType"
      />Escoger rango de años</label>

    <div class="container_anios">
      <input type="date" name="periodoFirst" id="pFirst" class="form form-control" [disabled]="!cbxAnio" required="true" [(ngModel)]="first"/>
      <input type="date" name="periodoEnd" id="pEnd" class="form form-control" [disabled]="!cbxAnio" required="true" [(ngModel)]="end"/>
      <button type="button" role="button" name="" id="" class="btn" [disabled]="!first && !end" (click)="filtrar()">
        Filtrar
      </button>
    </div>
  </div>
    <div>
      <ngx-charts-line-chart
      [view]="view"
      [scheme]="colorScheme"
      [legend]="legend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [timeline]="timeline"
      [results]="multi"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
    >
    </ngx-charts-line-chart>
    </div>
  </div>
