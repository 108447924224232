<main class=" px-4 py-5">
  <!-- MENU LATERAL -->

  <div class="w-400 my-5 py-5">
    <section class="section_enlaces">
      <h3 class="">ENLACES</h3>
      <ol class="servicios">
        <li>
          <a role="button" (click)="getParametroDos(6)">
            <span></span> Biblioteca Municipal
            </a>
        </li>
        <li>
          <a role="button" (click)="getParametroDos(1)" >
            <span></span> Alumbrado público
          </a>
        </li>
        <li>
          <a role="button" (click)="getParametroDos(2)">
            <span></span> Estadio
          </a>
        </li>

        <li>
          <a role="button" (click)="getParametroDos(3)">
            <span></span> Piscina Municipal
          </a>
        </li>
        <li>
          <a role="button" (click)="getParametroDos(5)">
            <span></span> Áreas verdes
            </a>
        </li>
        <li>
          <a role="button" (click)="getParametroDos(4)">
            <span></span> Gestión integral </a>
        </li>

      </ol>
    </section>
  </div>

  <!-- FIN DE MENU LATERAL -->
  
  <div class="container ms-3 me-2" id="featured-3">
    <h2 class="px-4 py-3 title"><span></span> SERVICIOS : {{titulo}}</h2>
    <div class="row d-flex g-4 py-5 px-2 row-cols-1 row-cols-lg-3">
      <div class="feature col tarjeta" *ngFor="let servicio of resultado ">
        <p class="detalle">{{titulo}}</p>
        <div class="bg-gradient img_container_servicio " >
          <img *ngIf="titulo == 'BIBLIOTECA MUNICIPAL'" src="assets/img/biblioteca.png" alt="" />
          <img *ngIf="titulo == 'ESTADIO'"src="assets/img/estadio.jpg" alt="">
         <!--  <img *ngIf="titulo == 'ALUMBRADO PUBLICO'"src="assets/img/estadio.jpg" alt="">
          <img *ngIf="titulo == 'MANEJO E IMPLEMENTACIÓN DE ÁREAS VERDES URBANAS'"src="assets/img/estadio.jpg" alt="">
          <img *ngIf="titulo == 'GESTIÓN INTEGRAL DE RESIDUOS SÓLIDOS'"src="assets/img/estadio.jpg" alt="">
          <img *ngIf="titulo == 'PISCINA MUNICIPAL'"src="assets/img/estadio.jpg" alt=""> -->
        </div>
        <div class="tarjeta_body">
          <h3 class="">
            {{servicio.titulo.replaceAll('Ã‰', 'É')
            .replaceAll("Ã","Í")
            .replaceAll('Ã“', 'Ó') 
            .replaceAll("Ãš","Ú")
            .replaceAll('Ã‘','Ñ')
            .replaceAll("NÂº ","Nº")
            .replaceAll("Â°","º")
            }}
          </h3>
          <p [innerHTML]='servicio.brevedescripcion.split(" ").slice(0,30).join(" ") + "..."'>
          </p>
        </div>
        <div class="footer_tarjeta">
          <p>{{ Meses[servicio.mes] }} {{ servicio.dia }},{{ servicio.anio }}</p>
          <a role="button" (click)="mostrarNoticia(servicio.idnoticia)" class=""> Leer Más </a>
        </div>
      </div>
    </div>
  </div>


  <!-- MENU LATERAL -->
  <div class=" my-5 py-5 w-480">
    <div class="container ">
      <app-slider3></app-slider3>
      <section class="seccion_noticias">
        <h3 class="">NOTICIAS RELACIONADAS</h3>
        <article class="noticias_items">
          <div  *ngIf="listaNoticias">
            <li *ngFor="let noticia of listaNoticias.slice(0,5)">
              <p  class="noticia_title" >
                {{noticia.titulo.replaceAll('Ã‰', 'É')
                .replaceAll("Ã","Í")
                .replaceAll('Ã“', 'Ó') 
                .replaceAll("Ãš","Ú")
                .replaceAll('Ã‘','Ñ')
                .replaceAll("NÂº ","Nº")
                .replaceAll("Â°","º")
                }}
              </p>
              <div class="noticia_footer">
                <p>{{ Meses[noticia.mes] }} {{noticia.dia }},{{ noticia.anio }}</p>
                <a role="button" (click)="mostrarNoticia(noticia.idnoticia)" class=""> Leer Más ></a>
              </div>
            </li>
          </div>
        </article>
      </section>
    </div>
    
  </div>
</main>
