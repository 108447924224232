import { Component,  OnDestroy,  OnInit, ViewChild } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Subject } from 'rxjs';

/* Ya no se necesita la importacion del map defsde versiones >7.5 */
import { map } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../environments/environments';
const apiUrl = `${environment.other.apiUrl}`;
@Component({
  selector: 'app-oficinas',
  templateUrl: './oficinas.component.html',
  styleUrls: ['./oficinas.component.css']
})
export class OficinasComponent implements OnInit , OnDestroy {

  //varaibles necesarias
  @ViewChild(DataTableDirective, { static: false })
  dtOptions: DataTables.Settings = {};

    /*Esta variable sirve para almacenar la ruta del enpoint -> Remplazar por la ruta de boot*/
  //url =  "http://localhost:8083/areas/listar";
  url = `${apiUrl}areas/listar`;


  data: any = [];
  dtTrigger: Subject<any> = new Subject<any>();

  //constructor de la vista
  constructor(private http: HttpClient){}


  //metodo que se incia al cargar la vista
  ngOnInit(): void {
    this.http.get(this.url)
    .subscribe((data) => {
      this.data = data;
     
      this.dtTrigger.next(this.data);
    })  
    
    this.dtOptions = {
      
     /*  columns:[
        {title:"nombre",data:"nombre"},{title:"observacion",data:"observacion"},
        {title:"correo",data:"correo"},{title:"url",data:"url"}
      ], */
      columns:[
        {title:"nombre",data:"nombre"},
        {title:"observacion",data:"observacion"},
        {title:"telefono",data:"telefono"},
        {title:"telefono",data:"telefono"},
        {title:"correo",data:"correo"},
        /* {title:"url",data:"url"} */
      ], 

      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
    };
  }

  //trigger para elimiar y poder limpiar la tabla

  ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
  }

}

export interface Area{

}
/* `idarea` int NOT NULL AUTO_INCREMENT,
`nombre` varchar(150) CHARACTER SET latin1 COLLATE latin1_swedish_ci NOT NULL,
`idarearef` int DEFAULT NULL,
`estado` int DEFAULT '1',
`observacion` varchar(150) CHARACTER SET latin1 COLLATE latin1_swedish_ci NOT NULL,
`telefono` varchar(20) NOT NULL,
`correo` varchar(150) CHARACTER SET latin1 COLLATE latin1_swedish_ci NOT NULL,
`url` varchar(100) CHARACTER SET latin1 COLLATE latin1_swedish_ci NOT NULL,
PRIMARY KEY (`idarea`) */