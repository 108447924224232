<div class="container  container_consolidado">
  <div>
    <h4 class="pt-4">Consolidado de estadisticas por año</h4>
  </div>
  <div class="container_inputs">
    

    <div class="container_anios">
      <label for="cbxanios">Escoger año:</label>
      <input type="date" name="anio" id="anio" class="form form-control"  required="true" [(ngModel)]="anio"/>
      <button type="button" role="button" name="" id="" class="btn" [disabled]="!anio" (click)="filtrar()">
        Filtrar
      </button>
    </div>
    <div class="container_legend"><p> <span></span>1. Nacimientos</p> <p><span></span>2. Defunciones</p> <p><span></span>3. Matrimonios</p> <p><span></span>4. Divorcios</p> </div>
  </div>

  <div class="container_graphics">
    <!-- Ngx charts      --><!-- GRAFICA 1 -->
      <ngx-charts-advanced-pie-chart
        [view]="view"
        [scheme]="colorScheme"
        [results]="single"
        [gradient]="gradient"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
      >
      </ngx-charts-advanced-pie-chart>
    <!-- End Ngx charts  -->
  </div>
</div>

