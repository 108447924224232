<main class="container pt-5 pb-5">
  <div class="navegacion mb-5">
    <p><a routerLink="/home">Home</a> > Noticias </p>
  </div>
  
  <div class="row g-5">
    <div class="col-md-8">
      
      <h1 class="pb-4 mb-4 fst-italic border-bottom">
        Noticias
      </h1>
      <div *ngFor="let noti of listaNoticias.slice(startIndex, endIndex)">
        <article class="blog-post">
          <h5 class="blog-post-title mb-1" role='button' (click)="mostrarNoticia(noti.idnoticia)">
            {{ noti.titulo.replaceAll('Ã','Á')
            .replaceAll('Ã‰', 'É')
            .replaceAll("Ã","Í")
            .replaceAll('Ã“', 'Ó') 
            .replaceAll("Ãš","Ú")
            .replaceAll('Ã‘','Ñ')
            .replaceAll("NÂº ","Nº")
            .replaceAll("Â°","º")
            .replaceAll("Ã³","ó")
            .replaceAll("&deg;","º")
            }}
          </h5>
          <p class="blog-post-meta">            
            <span>{{ listaMeses[noti.mes] }} {{ noti.dia }},{{ noti.anio }}.</span>
          </p>
          <!-- ,  by <a href="#">Mark</a> -->
          <!--  002- 2019-MPL  -->
          <p [innerHTML]='noti.brevedescripcion.split(" ").slice(0, 100).join(" ").replaceAll("âœ…","")'>
          </p>
          <!-- Ã -->
        </article>
        <hr/>
      </div>
      <div class="d-flex justify-content-between">
        <a type="button" (click)="mostratMasNoticias()" class="">Ver más</a>
        <a type="button" (click)="regresar()" class=""> <-Regresar </a>
      </div>
    </div>

    <!-- Barra Lateral -->
    <div class="col-md-4">
      <div class="position-sticky" style="top: 2rem">
        <div class="p-4 mb-3 bg-light rounded">
          <div class="mb-0">
            <app-slider3></app-slider3>
          </div>
        </div>

        <div class="p-4">
          <h4 class="fst-italic">Lo más visto</h4>
          <ol class="list-unstyled mb-0">
            
            
            <li><a href="https://www.gob.pe/23228-solicitar-certificado-domiciliario?child=15399">> Solicitar certificado domiciliario a la Municipalidad Provincial de Lambayeque</a></li>
            <li><a href="https://www.gob.pe/institucion/munilambayeque/pages/15404-obtener-certificado-de-salubridad-para-locales-comerciales-en-lambayeque">> Obtener certificado de salubridad para locales comerciales. </a></li>
            <li><a href="https://www.gob.pe/20414-obtener-copia-certificada-de-una-partida-o-acta-de-nacimiento-matrimonio-o-defuncion?child=16363">> Solicitar copia certificada de partida de nacimiento, matrimonio o defunción </a></li>
            <li><a href="https://www.gob.pe/20399-solicitar-acceso-a-la-informacion-publica?child=15479">> Solicitar información pública a la Municipalidad Provincial de Lambayeque</a></li>
            <li><a href="https://reclamos.servicios.gob.pe/?institution_id=284" target="_blank">> Libro de reclamaciones</a></li>
            <li><a routerLink="/gobierno/TUPA">> TUPA</a></li>
            <li><a routerLink="/gobierno/informacion-publica">> Acceso a la información pública</a></li>
          </ol>
        </div>

        <!-- <div class="p-4">
          <h4 class="fst-italic">Redes Sociales y Contacto</h4>
          <ol class="list-unstyled">
            <li><a href="#">Gmail</a></li>
            <li><a href="#">Youtube</a></li>
            <li><a href="#">Facebook</a></li>
          </ol>
        </div> -->
      </div>
    </div>
  </div>
</main>
