<main class="mb-5">
  <!-- <div
    class="position-relative overflow-hidden p-2 p-md-2 m-md-2 text-center bg-light"
  >
    <div class="mx-auto  my-2">
      <h1 class="display-4 text-center">Lambayeque <span>Ciudad Evocadora </span></h1>
      <p class=" fw-normal text-center">
       
        
      </p>
      <a class="btn btn-outline-success" href="#">Ver más</a>
    </div>
    <div class="product-device shadow-sm d-none d-md-block"></div>
    <div class="product-device product-device-2 shadow-sm d-none d-md-block">
     
    </div>
  </div> -->

  <!--  -->
  <div class="bg-img1">
    <div class="container">
      <h1 class="px-4 py-3 pt-5 titulo"><span></span> TURISMO LAMBAYEQUE</h1>
      <div class="container_first bg-first">
        <!-- <iframe width="600px "  height="400"   src="https://www.youtube.com/watch?v=TbO88ywChf0" frameborder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe> -->
        <iframe
          width="560"
          height="315"
          class="iframe_youtube"
          src="https://www.youtube.com/embed/TbO88ywChf0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        >
        </iframe>
        <div class="parrafo">
          <h4>Lambayeque - Ciudad Evocadora</h4>
          <p>
            La región de Lambayeque está ubicada en la costa norte del Perú,
            limitando al norte con Piura, al sur con La Libertad y al este con
            Cajamarca. Esta región es rica en historia, cultura y gastronomía,
            lo que la convierte en un destino turístico atractivo para los
            visitantes.
          </p>
        </div>
      </div>
      <div class="container_first bg-second">
        <!-- <iframe width="600px "  height="400"   src="https://www.youtube.com/watch?v=TbO88ywChf0" frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe> -->
        <div class="parrafo">
          <h4>Lambayeque Compra</h4>
          <p>
            La imagen a continuación es acerca del Mapa de Lambayeque.
            Lambayeque es conocido en Perú como el departamento con los dulces
            más exquisitos.
          </p>
        </div>
        <iframe class="iframe_youtube"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/pFzYJD3bmJ8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
  </div>
  <div class="bg-img2">
    <section class="py-5 border-bottom" id="features">
      <div class="container px-5 my-5">
        <div class="row gx-5">
          <div class="col-lg-4 mb-5 mb-lg-0">
            <div
              class="feature bg-primary bg-gradient text-white rounded-3 mb-3"
            >
              <i class="bi bi-collection"></i>
            </div>
            <h2 class="h4 fw-bolder">¿Por qué?</h2>
            <p>
              Expresa la unión de los íconos y colores naturales lambayecanos,
              el rojo cinabrio, que emplearon nuestras culturas prehispánicas
              para teñir huacos y frisos; el ocre dorado, de la belleza de sus
              joyas emblemáticas como el Tumi de Oro; y el celeste que simboliza
              nuestra riqueza marina en donde desembarcó Naylamp como primer
              habitante de estas tierras.
            </p>
            <!-- <a class="text-decoration-none" href="#!">
                      Call to action
                      <i class="bi bi-arrow-right"></i>
                  </a> -->
          </div>
          <div class="col-lg-4 mb-5 mb-lg-0">
            <div
              class="feature bg-primary bg-gradient text-white rounded-3 mb-3"
            >
              <i class="bi bi-building"></i>
            </div>
            <h2 class="h4 fw-bolder">Lambayeque - Bicentenario 2022</h2>
            <p>
              La pandemia provocada en el mundo a causa del Covid 19, cerró
              miles de puertas, clausuró muchas actividades, y en nuestro Perú
              el turismo tuvo una importante afectación. Han pasado más de siete
              meses y existe una "nueva normalidad" en la que ...
            </p>
            <a
              class="text-decoration-none"
              href="https://www.munilambayeque.gob.pe/bicentenario/"
              target="_blank"
            >
              seguir enlace ...
            </a>
          </div>
          <div class="col-lg-4">
            <div
              class="feature bg-primary bg-gradient text-white rounded-3 mb-3"
            >
              <i class="bi bi-toggles2"></i>
            </div>
            <h2 class="h4 fw-bolder">
              700-1300 d.C.: Cultura Sicán o Lambayeque
            </h2>
            <p>
              Heredera de la cultura Mochica y una de las más representativas de
              la costa norte. Su origen es asociado a la Leyenda de Naylamp.
              Ocuparon los valles de Olmos, Motupe, La Leche y Lambayeque.
              Destaca por sus icónicos gobernantes como la Sacerdotisa de
              Chornancap, sus magníficos sitios arqueológicos como Chotuna
              Chornancap, Túcume y en el Santuario Histórico Bosque de Pómac.
            </p>
            <!-- <a class="text-decoration-none" href="#!">
                      seguir
                      <i class="bi bi-arrow-right"></i>
                  </a> -->
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="d-md-flex flex-md-equal ">
    <div
      class="text-bg-warning w-100 pt-3 px-2 pt-md-5 px-md-3 text-center overflow-hidden"
    >
      <div class="my-3 py-3">
        <h2 class="display-5">Destinos Turisticos</h2>
        <p class="lead">
          ¡Sumérgete en la magia de los paisajes y la cultura Lambayecana, una
          experiencia turística única que no olvidarás!
        </p>
      </div>
      <div>
        <img
          src="assets/img/turismo/pimentel.jpg"
          class="bg-light shadow-sm mx-auto"
          style="width: 80%; height: 300px; border-radius: 21px 21px 0 0"
          alt=""
        />
      </div>
      <a
        class="btn btn-outline-danger p-2 mt-4 mb-4"
        (click)="mostrarDestinos()"
      >
        ver más</a
      >
    </div>

    <div
      class="bg-rosado w-100 pt-3 px-2 pt-md-5 px-md-3 text-center overflow-hidden"
    >
      <div class="my-3 py-3">
        <h2 class="display-5">Platos Típicos</h2>
        <p class="lead">
          ¡Deléitate con la diversidad culinaria de Perú, una explosión de
          sabores en cada plato!
        </p>
      </div>
      <div>
        <img
          src="assets/img/turismo/platos_tipicos.jpg"
          class="bg-light shadow-sm mx-auto"
          style="width: 80%; height: 300px; border-radius: 21px 21px 0 0"
          alt=""
        />
      </div>
      <a class="btn btn-outline-danger p-2 mt-4 mb-4" (click)="mostrarPlatos()"
        >ver más</a
      >
    </div>
    <div
      class="text-bg-success w-100 pt-3 px-3 pt-md-5 px-md-3 text-center overflow-hidden"
    >
      <div class="my-3 py-3">
        <h2 class="display-5">Festividades</h2>
        <p class="lead">
          ¡Celebra la vida al estilo peruano, donde la música, el color y la
          alegría te envuelven en un ambiente de fiesta inolvidable!
        </p>
      </div>
      <div>
        <img
          src="assets/img/turismo/kingkong.jpg"
          class="bg-light shadow-sm mx-auto"
          style="width: 80%; height: 300px; border-radius: 21px 21px 0 0"
          alt=""
        />
      </div>
      <a class="btn btn-outline-light p-2 mt-4 mb-4" (click)="mostrarFiestas()"
        >Ver más</a
      >
    </div>
    <div class="w-100 pt-3 px-3 pt-md-5 px-md-3 text-center overflow-hidden bg-celeste" >
      <div class="my-3 py-3">
        <h2 class="display-5">Bailes típicos</h2>
        <p class="lead">
          ¡Descubre el sabor y la alegría de Perú a través de sus bailes
          típicos!
        </p>
      </div>
      <div>
        <img
          src="assets/img/turismo/bailestipicos.jpg"
          class="bg-light shadow-sm mx-auto"
          style="width: 80%; height: 300px; border-radius: 21px 21px 0 0"
          alt=""
        />
      </div>
      <a class="btn btn-outline-danger p-2 mt-4 mb-4" (click)="mostrarBailes()"
        >Ver más</a
      >
    </div>
  </div>

  <div class="container">
    <div *ngIf="band == 1" id="destinos">
      <h2 class="my-5 title">
        Destinos Turisticos <span (click)="cerrar()">X </span>
      </h2>
      <section class="container_items">
       <!--  <article class="container_item">
          <h3>1. Chiclayo</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/chiclayo.jpg" alt="" />
          </div>
          <p>
            Chiclayo es una ciudad ubicada en la costa norte de Perú, en la
            región de Lambayeque. Fue fundada en 1720 y es conocida como la
            "Capital de la Amistad". Cada 18 de abril, Chiclayo celebra su
            aniversario con una serie de eventos culturales y festividades que
            atraen a turistas de todo el país. La ciudad de Chiclayo es un
            importante centro comercial y turístico en la región norte de Perú.
            Es famosa por su rica historia arqueológica y sus playas
            espectaculares. Además, es conocida por su gastronomía única, que
            incluye platos como el arroz con pato y el ceviche de mero. Chiclayo
            es una ciudad vibrante, con una mezcla de influencias culturales de
            los pueblos indígenas, los españoles y los africanos. La ciudad
            cuenta con numerosos museos y sitios arqueológicos, como el Museo
            Tumbas Reales de Sipán, donde se exhiben las tumbas de antiguos
            gobernantes mochicas, y el complejo arqueológico de Huaca Rajada,
            donde se descubrió la tumba del Señor de Sipán.
          </p>
        </article> -->
        <article class="container_item">
          <h3>1. Museo Tubas Reales de Sipan</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/museo_tumbas_reales.jpg" alt="" />
          </div>
          <p>
            El Museo Tumbas Reales de Sipán es uno de los museos más importantes
            de Perú y del mundo, ubicado en la ciudad de Lambayeque. Este museo
            es famoso por exhibir los hallazgos arqueológicos más importantes de
            la cultura mochica, incluyendo la tumba del Señor de Sipán,
            descubierta en 1987. El museo cuenta con una impresionante colección
            de objetos de oro, plata y cobre, así como joyas y cerámicas, que
            fueron descubiertos en las tumbas de los antiguos gobernantes
            mochicas. Además, el museo ofrece información detallada sobre la
            cultura y la vida de los mochicas, lo que permite a los visitantes
            conocer mejor la historia precolombina de Perú. El edificio del
            museo fue diseñado para parecerse a una pirámide mochica, y cuenta
            con diferentes salas de exhibición que están conectadas por una
            pasarela de madera. La tumba del Señor de Sipán se encuentra en la
            sala principal del museo, y está protegida por una cúpula de vidrio
            que permite a los visitantes ver los restos del gobernante y los
            objetos que lo acompañan.
          </p>
        </article>

        <article class="container_item">
          <h3>2. Museo Bruning</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/bruning.jpg" alt="" />
          </div>
          <p>
            El Museo Arqueológico Nacional Brüning es el primer museo regional
            del Perú, fundado en el año 1921 por Hans Heinrich Brüning. Museo
            Nacional Bruning. Exhibe bienes culturales que explican la secuencia
            cronológica del desarrollo cultural del Perú, con cerámica
            representativa de las culturas de la región, destacando objetos de
            las culturas Mochica y Lambayeque.
          </p>
        </article>

        <article class="container_item">
          <h3>3. Pimentel</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/pimentel.jpg" alt="" />
          </div>
          <p>
            Playa Pimentel es considerado uno de los balnearios más bellos del
            Perú. Además, es un lugar ideal para practicar surf, windsurf y
            cualquier otro deporte acuático sobre tablas. En los alrededores hay
            diversas opciones de tiendas que ofrecen el alquiler de equipos para
            practicar deportes acuáticos. Además, es un sector turístico donde
            disfrutarás de extraordinarios restaurantes y bares. Otras de las
            opciones que tiene esta playa para los viajeros es el alquiler de
            hamacas. Así podrás, desde la comodidad de este instrumento de
            reposo, disfrutar de un cálido día bajo el sol o de un magnífico
            atardecer. En la Caleta Santa Rosa:
            <br />
            podrás disfrutar de una actividad ancestral del Perú. En este sitio
            se puede ver a los pescadores artesanales realizar sus actividades
            de pesca en los caballitos de totora, una embarcación que data de la
            época precolombina. Esta balsa de uso tradicional está hecha con los
            tallos y hojas de totora y tiene forma aguzada y curvada. ¡Un
            espectáculo digno de admirar!
            <br />
            El malecón de Playa Pimentel:
            <br />
            Otro atractivo muy frecuentado por los visitantes. Desde este
            espacio puedes obtener una excelente vista panorámica del balneario
            y, además, disfrutar de la degustación de diversos platos típicos en
            los puestos de comida. Estos están dispuestos en una especie de
            boulevard de paseo, donde también tienes la posibilidad de adquirir
            artesanías representativas de la localidad.
          </p>
        </article>

        <article class="container_item">
          <h3>4. Tinajones</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/tinajones.jpg" alt="" />
          </div>
          <p>
            Hasta hace 40 años era imposible imaginar el desarrollo de una
            agricultura estable en la totalidad del valle Chancay Lambayeque.
            Hoy gracias al Proyecto Tinajones, las tierras del inmenso valle
            aprovechan a través de las obras hidráulicas que conforman la
            Primera Etapa, las aguas provenientes de los ríos Chotano, Conchano
            y Chancay beneficiando el riego de más de 68,000 hectáreas de
            cultivos, generando una potencia de 95 MW y 600 GWh/año, a través de
            la central hidroeléctrica de Carhuaquero. Las principales obras del
            Sistema Tinajones fueron ejecutadas en el período 1950 a 1990 en el
            marco de la Primera Etapa con el apoyo financiero del Gobierno
            Alemán. Sus obras alcanzaron una inversión superior a los US$ 180
            Millones de dólares; siendo éstas las obras de derivación de los
            ríos Chotano y Conchano a través de los túneles Chotano y Conchano,
            respectivamente; obras de captación, almacena- miento y descarga del
            Reservorio de 320 MMC de capacidad, como la Bocatoma Racarumi, canal
            Alimentador, Reservorio Tinajones, Canal de Descarga y Obras de
            Distribución del Sistema de Riego, conformada por el Repartidor La
            Puntilla, Repartidor Desaguadero y Canal Taymi.
          </p>
        </article>

        <article class="container_item">
          <h3>5. Iglesia San Pedro</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/iglesia_sanpedro.jpg" alt="" />
          </div>
          <p>
            La Iglesia San Pedro de Lambayeque es la principal iglesia en la
            ciudad homónima. Está bajo propiedad de la Iglesia católica. El
            edificio se empezó a mediados del XVII. La construcción fue
            declarado Monumento Histórico mediante R.S. N.º-2900-72-ED. La
            iglesia se caracteriza por su estilo renacentista. Fue construida de
            ladrillo y cal y canto. La iglesia presenta la portada de pies, dos
            torres gemelas, una nave principal y dos laterales.
          </p>
        </article>
        <article class="container_item">
          <h3>6. Museo Arqueológico Chotuna</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/chotuna.jpg" alt="" />
          </div>
          <p>
            El Museo de Sitio Chotuna-Chornancap es un museo peruano, que está
            situado en el departamento de Lambayeque. Fue inaugurado el 19 de
            septiembre de 2009.2​Está ubicado en el complejo arqueológico
            Chotuna Chornancap, a 9 km de la ciudad de Lambayeque. El museo
            recrea los hechos históricos de la leyenda de Naylamp, como el
            desembarco,3​ puesta del ídolo; el desembarco, muerte y funeral. La
            colección está compuesta de cerámica, metales y fragmentos de los
            frisos encontrados en las excavaciones del sitio arqueológico.
          </p>
        </article>
      </section>
    </div>
    <div *ngIf="band == 2">
      <h2 class="my-5 title">
        Platos Típicos <span (click)="cerrar()">X </span>
      </h2>
      <section class="container_items">
        <article class="container_item">
          <h3>1. Arroz con pato</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/arrozconpato.jpg" alt="" />
          </div>
          <p>
            El arroz con pato es el plato típico por excelencia del departamento
            de Lambayeque. También se cocina en otras partes del país,
            presentando diversas variantes. Su presencia en la gastronomía de la
            región se remonta al siglo XIX, según se ha documentado a través de
            escritos históricos y literarios. La base de su elaboración, además
            de la carne de pato, es el rehogado inicial de cebolla, pimienta,
            ají amarillo, manteca, sal y cilantro. El pato, el arroz y la
            cerveza completan la lista de ingredientes de este plato
            característico de la región.
          </p>
        </article>
        <article class="container_item">
          <h3>2. Seco de cabrito</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/cabrito.jpg" alt="" />
          </div>
          <p>
            El seco de cabrito es otro de los platos más representativos de la
            región de Lambayeque, así como del resto de la zona norte de Perú.
            Se prepara con carne de cabrito guisada, acompañada de ingredientes
            autóctonos como el ají amarillo, la chicha y el zapallo, además de
            cebolla, ajo y cilantro. La guarnición consiste generalmente en
            frijoles y yuca cocida.
          </p>
        </article>

        <article class="container_item">
          <h3>3. El chinguirito</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/chinguirito.jpg" alt="" />
          </div>
          <p>
            El chiringuito es una variedad de ceviche, que en Perú es Patrimonio
            Cultural de la Nación. En el caso concreto de Lambayeche, para la
            elaboración de su variante se usa pescado blanco deshilachado
            (generalmente se usa raya) junto con otros ingredientes para
            sazonarlo. Estos ingredientes incluyen el limón, la cebolla, el ají,
            el cilantro, la yuca, camotes, choclos y sal. El sabor es muy
            similar al del ceviche tradicional, de ahí que se considere una
            variante del mismo plato.
          </p>
        </article>

        <article class="container_item">
          <h3>4. Arroz con mariscos</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/arrozconmariscos.jpg" alt="" />
          </div>
          <p>
            Una de las bases de la cocina tradicional de Lambayeque es el uso de
            productos del mar. Históricamente, en las costas del Pacífico se
            pescaron pescados y mariscos empleados a diario en la cocina de la
            región. Esta tradición se remonta a las épocas precoloniales. Por
            eso, estos ingredientes siguen siendo hoy muy importantes en la
            gastronomía de este departamento de Perú. Un buen ejemplo es el
            arroz de mariscos, que se cocina a base de arroz, pescados y
            diferentes especies de mariscos, como calamares, camarones y
            pepitonas.
          </p>
        </article>

        <article class="container_item">
          <h3>5. El King Kong</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/kingkong.jpg" alt="" />
          </div>
          <p>
            El King Kong de manjar blanco es un postre típico de Perú cuyos
            orígenes se sitúan en la región de Lambayeche. Se considera
            patrimonio regional y su nombre se debe al tamaño del dulce,
            comparado con el del gorila gigante del cine. Se trata de un alfajor
            de gran tamaño hecho a base de galletas de harina, mantequilla,
            yemas de huevo y leche, compactadas y hechas un todo con manjar
            blanco.
          </p>
        </article>
      </section>
    </div>
    <div *ngIf="band == 3">
      <h2 class="my-5 title">
        Bailes Típicos <span (click)="cerrar()">X </span>
      </h2>
      <section class="container_items">
        <article class="container_item">
          <h3>1. El tondero</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/tondero.jpg" alt="" />
          </div>
          <p>
            El tondero es uno de los bailes más representativos del norte de
            Perú. Es muy común en los departamentos de Lambayeque y Piura.
            <br />
            Su origen se encuentra en los migrantes llegados desde el sur de España y el este de Europa. Su adaptación en Perú introdujo algunas modificaciones sobre las características originales.

En su versión clásica, es un baile mixto alegre y vivo. Su representación imita la danza de las aves durante su ritual de apareamiento. Los pasos específicos a ejecutar dependerán de las habilidades de cada uno de los bailarines.

Entre los instrumentos utilizados para la música destacan la guitarra, el cajón peruano y las cucharas. En las versiones más modernas se agregan tambores y trompetas.
          </p>
        </article>
        <article class="container_item">
          <h3>2. La marinera norteña</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/marineranortenia.jpg" alt="" />
          </div>
          <p>
            Este baile tiene raíces africanas y españolas. Es el baile más
            típico y conocido de la costa peruana. Se considera una evolución de
            otro baile tradicional de Lambayeque llamado zamacueca, muy popular
            en el siglo XIX.
          </p>
        </article>

        <article class="container_item">
          <h3>3. Vals Peruano</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/valsperuano.jpg" alt="" />
          </div>
          <p>
            El origen cultural de este baile se encuentra en el Perú de los
            siglos XVIII y XIX. Surge de una mezcla de ritmos criollos y
            afroperuanos, y deriva, evidentemente, del original vals vienés,
            aunque con características propias. En la parte instrumental
            destacan la guitarra criolla, el cajón peruano, las castañuelas, el
            acordeón y el piano. Como se deduce de algunos de esos instrumentos,
            es un baile elegante que se ejecutaba en los salones de la época.
          </p>
        </article>

        <!-- <article class="container_item">
          <h3>4.  Huaylas</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/huaylas.jpg" alt="" />
          </div>
          <p>
            También conocido con el nombre de Huaylash, es una danza tradicional
            indígena. Su origen parece remontarse a la época previa a la
            colonia, y geográficamente se localiza en las serranías del norte de
            Perú. Esto se debe al vínculo entre este baile y las siembras y
            cosechas de productos de la tierra. Antiguamente, era la expresión
            alegre de los campesinos en esas épocas del año en que tocaba
            trabajar la tierra y recibir sus frutos. Actualmente, conserva ese
            espíritu y algunos pueblos todavía celebran dichas festividades
          </p>
        </article> -->

        
      </section>
    </div>
    <div *ngIf="band == 4">
      <h2 class="my-5 title">
        Festividades <span (click)="cerrar()">X </span>
      </h2>
      <section class="container_items">
        <article class="container_item">
          <h3>1. Festival del King Kong</h3>
          <div class="container_item_img">
            <img src="assets/img/turismo/fiestakingkong.jpg" alt="" />
          </div>
          <p>
            La Asociación de Productores de King Kong de Lambayeque (APROKLAM), desde hace 05 años celebra el Festival del King Kong durante los días de Fiestas Patrias, el 28 y 29 de julio. En este festival el visitante puede darse el gusto de participar en todas las actividades programadas como shows artísticos – culturales, exposición de platos típicos, sorteos, exposiciones y ventas de los diferentes dulces como el manjar blanco, dátiles, alfajores, alfeñique y membrillo. Además puede observar desde muy cerca su elaboración y degustación gratuita del King Kong más grande del mundo y otras sorpresas más. El objetivo de este festival es posicionar a este alfajor, como dulce típico importante para promocionarlo como producto bandera de nuestra Región Lambayeque.
            <br>
            El festival del King Kong se realiza en el Parque Infantil Victoria Mejía de García (Frente al Museo Bruning). El nombre de este dulce típico se debe a que por la época de los años 20 recién se comenzaba a pasar la primera versión de la película King Kong y no faltó la picardía popular que relacionara la forma maciza y el tamaño del alfajor con el gran gorila y le pareciera ideal este nombre para este dulce.
          </p>
        </article>
        
      </section>
    </div>
  </div>
</main>
