
import { Component, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { EstadisticasService } from '../servicios/estadisticas.service';
import { map, tap } from 'rxjs';
@Component({
  selector: 'app-consolidadocomp',
  templateUrl: './consolidadocomp.component.html',
  styleUrls: ['./consolidadocomp.component.css']
})
export class ConsolidadocompComponent {

  valueTipo: string = "";
  valueType: string = "";
  end: string = "";
  first: string = "";
  cbxTodo: Boolean = false;
  cbxAnio: Boolean = false;

  view: [Number, Number] = [900, 500];
  multi = [
    {
      "name": "",
      "series": [
      ]
    },

  ];
  colorScheme = {
    domain: ['#06F744']
  };
  constructor(private estidisticaService: EstadisticasService) {
    //  Object.assign(this, { single })
  }
  /* variables de la otra grafica */
  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;
  /* --------------- */
  ELEMENT_DATA: Consolidado[] = [];
  single: any = [];




  getConsolidado(pFirst:number, pEnd:number,tipo:string) {
    //usamos promesas para evitar que la grafica se renderize antes de llenar los datoss
    //este metodo recibe los datos de todos los años pero de un solo tipo
    this.estidisticaService.getFiltrarAnios(pFirst, pEnd, tipo).pipe(
      map(data => {
        this.ELEMENT_DATA = data;
        return this.recorrerJsonAConsolidado();
      })
    ).subscribe(single => {
      this.single = single;


      const series = this.single.map((item: any) => {
        return { name: item.name, value: item.value };
      });
      this.multi = this.updateMulti(this.valueTipo, series);

    });
  }

  recorrerJsonAConsolidado() {
    this.single = [];
    const single = this.ELEMENT_DATA.map(item => {
      return {
        name: item.anio.toString(),
        value: item.cantidad
      };
    });
    return single;
  }
  updateMulti(name: string, series: any) {
    this.multi = [];
    const multi = [{
      name: name,
      series: series
    }]
    return multi;
  }

  onSelect(data: any): void {
    /*  console.log("dataselecr", data) */
    //console.log('Item clicked', );
    JSON.parse(JSON.stringify(data))
  }

  onActivate(data: any): void {
    /*  console.log("datasele2", data) */
    //console.log('Activate', );
    JSON.parse(JSON.stringify(data))
  }

  onDeactivate(data: any): void {
    //console.log('Deactivate',);
    JSON.parse(JSON.stringify(data));
  }

  cambiarCBX1() {
    this.first = "2001";
    this.end ="2050";
    this.getConsolidado(parseInt(this.first),parseInt(this.end),this.valueType);
    this.cbxAnio = false;
  }
  cambiarCBX2() {
    this.cbxTodo = false;
    //this.getConsolidado();
  }

  filtrar(){
    
    this.getConsolidado(parseInt(this.first),parseInt(this.end),this.valueType);
  }

  clear(){
    this.cbxAnio = false;
    this.cbxTodo = false;
    this.single= []
    this.multi = []

  }

}

export interface Consolidado {

  idconsolidado: Number;
  tipo: Number;
  subtipo: Number;  //'según: ordinaria, extraordinaria, parte policial, judicial, masiva, etc.',
  mes: Number;
  anio: Number;
  sexo: Number;
  cantidad: number;

}