<div class="container pt-4">
    <h1>> Organigrama</h1>
  <div class="container_img">
    <img src="assets/img/img_organigrama_municipio.jpg" alt="">
  </div>
  <div class="enlaces-externos">
    <h3>Otros organigramas</h3>
    <ul>
      <li><a href="">Descarga(*) el organigrama municipal 2016 en formato PDF.</a></li>
    <li><a href="">Descarga(*) el organigrama municipal 2014 en formato PDF.</a></li>
    <li><a href="">Descarga(*) el organigrama municipal 2010 en formato PDF.</a></li>
    </ul>
  </div>
</div>
