import { Component } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SearchServiceService } from '../servicios/search-service.service';
import { NoticiaServiceService } from '../servicios/noticia-service.service';

@Component({
  selector: 'app-page-servicios',
  templateUrl: './page-servicios.component.html',
  styleUrls: ['./page-servicios.component.css']
})
export class PageServiciosComponent  {

  servicios:String[] = ["ALUMBRADO PUBLICO","ESTADIO","PISCINA MUNICIPAL","GESTIÓN INTEGRAL DE RESIDUOS SÓLIDOS",
                        "MANEJO E IMPLEMENTACIÓN DE ÁREAS VERDES URBANAS","BIBLIOTECA MUNICIPAL"]

  resultado:any[];
  listaNoticias:any=[];
  Meses: string[]=["","Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];;
  band:any;
  titulo:String;

  constructor(private router: ActivatedRoute,private route:Router,private searService:SearchServiceService,private serviceNoticias:NoticiaServiceService){
      this.band = 0;
      this.titulo = "";
      this.resultado =[];
  }

  ngOnInit(){
    this.getParametro();
    this.obtenerNoticias();
   
  }

  getParametro(){
    this.router.paramMap.subscribe( param =>
      {
        this.band = param.get("id");
        //console.log( "this.band ",this.band );
        this.titulo = this.servicios[this.band - 1];
        this.getObtenerResultados();
      }
    );
  }

  getObtenerResultados(){
    this.searService.getBusquedadNoticias(this.titulo).subscribe(
      data => {
        this.resultado = data;
        //console.log("tamano de lista: ",this.resultado.length)
      }
    );
  }


  public obtenerNoticias(){
    this.serviceNoticias.getNoticias().subscribe(
      (data) => this.listaNoticias = data);
    //console.log("ListanOTICIAS: "+this.listaNoticias)
  }

  getParametroDos(id:number){//esta funcion sirve para el menu lateral
    this.band = id;
    this.titulo = this.servicios[this.band - 1];
    this.getObtenerResultados();
  }


  mostrarNoticia(id:String):void{
    //console.log("idnoticia ",id)
    this.route.navigate([`/page-noticias/${id}`])
  }

}
