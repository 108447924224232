import { Component } from '@angular/core';
import { SearchServiceService } from '../servicios/search-service.service';
import { NoticiaServiceService } from '../servicios/noticia-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seguridad',
  templateUrl: './seguridad.component.html',
  styleUrls: ['./seguridad.component.css']
})
export class SeguridadComponent {
  keywords : String[] = ['segu','seguridad','vecino','junta','vecinal','policia','serenazgo','delincuencia','ratero'];
  busq: String = "seguridad";
  title: String = "SEGURIDAD CIUDADANA";
  resultado: any[];
  listaNoticias: any = [];
  Meses: string[] = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];;

  constructor(private route: Router, private searService: SearchServiceService, private serviceNoticias: NoticiaServiceService) {
    this.resultado = [];
  }


  ngOnInit() {
    this.obtenerNoticias();
    this.getResultados();
  }

  getBuscar() {
    
    if(this.keywords.some(word => this.busq.includes(word.toString())))
    {
      this.searService.getBusquedadNoticias(this.busq).subscribe(
        data => { this.resultado = data }
      );
    }
    
  }
  getResultados() {
    this.searService.getBusquedadNoticias(this.busq).subscribe(
      data => { this.resultado = data }
    );
  }

  public obtenerNoticias() {
   this.serviceNoticias.getNoticias().subscribe(
     (data) => this.listaNoticias = data);
   //console.log("ListanOTICIAS: " + this.listaNoticias)
 }  

  mostrarNoticia(id: String): void {
    console.log("idnoticia ", id)
    this.route.navigate([`/page-noticias/${id}`])
  }
}
