import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { EstadisticasService } from '../servicios/estadisticas.service';


@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.css', './estadisticas.componentS.scss']
})
export class EstadisticasComponent implements OnInit {

// Variables de para TABLA DE ANGNLAR MATERIAL 
  @ViewChild(DataTableDirective, { static: false })
  dtOptions: DataTables.Settings = {};

  ELEMENT_DATA: Estadistica[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['acta', 'fecha','apellidos', 'nombres', 'sexo', 'documento',  'subtipo'];

  //creamo el elemento datasource
  dataSource = new MatTableDataSource<Estadistica>(this.ELEMENT_DATA);


  constructor(private _liveAnnouncer: LiveAnnouncer, private estidisticaService: EstadisticasService) { }

  ngOnInit(): void {
    this.getEstadisticas();
  }

  getEstadisticas() {
    this.estidisticaService.getEstadisticasAll().subscribe
      (
        (data) => {
          this.ELEMENT_DATA = data
          //console.log("elem esta: ", this.ELEMENT_DATA)
          //configuramos la tabla despues de llenar los datos:
          this.dataSource = new MatTableDataSource<Estadistica>(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      );
  }

  announceSortChange(sortState: Sort) {

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }



}

export interface Estadistica {

  idestadisticas: Number;
  sexo: Number;
  subtipo: Number;
  fecha:String;
  apellidos: String;
  nombres: String;
  acta: CharacterData;
  documento: String;
  tipodocumento: String; // L.E. / DNI 

} 