import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';

const apiUrl = `${environment.other.apiUrl}`;
const headers = new HttpHeaders().set('Content-Type', 'application/json');
const options = { headers };
@Injectable({
  providedIn: 'root'
})
export class ConvocatoriaService {

  url =`${apiUrl}convocatoriascas/listar`; 
  url2 = `${apiUrl}directorios/listar`;
  constructor(private _http:HttpClient) { }

  getConvocatorias(periodoFirst: string, periodoEnd: string): Observable<any>{

    //creamos nuestros parametros para pasar al endpoint:
    //let params = new HttpParams();
    let parametros = new HttpParams();
    parametros = parametros.append('periodoFirst',periodoFirst);
    parametros = parametros.append('periodoEnd',periodoEnd);
    return this._http.get(this.url,{headers,params:parametros});
  }


  getDirectorios():Observable<any>{
    return this._http.get(this.url2,options);
  }
}
