import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConvocatoriaService } from '../servicios/convocatoria.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.css']
})
export class DirectorioComponent {
  ELEMENT_DATA: Convocatoria[] = [
  ];
  periodoFirst?: Date;
  periodoEnd?: Date;
  first: string = '';
  end: string = '';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  /* Preparamos la tabla */
  displayedColumns: string[] = ['iddirectorio', 'unidadorganica', 'correoinstitucional', 'telefono'];

  dataSource = new MatTableDataSource<Convocatoria>(this.ELEMENT_DATA);

  constructor(private servicioCAS: ConvocatoriaService, private _liveAnnouncer: LiveAnnouncer) { }


  /* FILTRO VARIABLES:  */
  /* filterValue = ''; */


  ngOnInit() {
    this.getConvocatoriasCAS();
  }

  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
  }
  getConvocatoriasCAS() {
    this.periodoFirst = new Date(this.first);
    this.periodoEnd = new Date(this.end);

    this.servicioCAS.getDirectorios()
      .subscribe(
        (data) => {

          /* 1. OBTENGO LA DATA DEL BAACKEND */
          this.ELEMENT_DATA = data;
          //console.log("elemet data: ",this.ELEMENT_DATA)
          /* console.log("ELEMET_DATA",this.ELEMENT_DATA); */

          /* 2. ASIGNO LA DATA AEL DATASOURCE QUE SE MOSTRAR EN LA VISTA */
          this.dataSource = new MatTableDataSource<Convocatoria>(this.ELEMENT_DATA);

          /* 3. AGREGO LA PAGINACION - IMPORTANTE ESTE ORDEN Y SECUENCIA QUE SE SIGUE */
          this.dataSource.paginator = this.paginator;

          this.dataSource.sort = this.sort;
        }
      );
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}

export interface Convocatoria {

  idconvocatoriascas: number;
  nombre: String;
  periodo: String;
  bases: String;
  fe_erratas: String;
  resultados: String;
  resultadosfinales: String;


}
