import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent {

 /*  urlTramite:String ="" ;
  urlLibroReclamaciones:String = "";
  urlPortalTransparencia =""; */
  url:String="";
  band:String = "";

  constructor(private router : Router,private routerA: ActivatedRoute,private cookieService: CookieService){
   
  }
  setCookieWithSameSite(){
    this.cookieService.set('myCookie', 'myValue', 30, '/', undefined, false, 'Lax');
  }

  ngOnInit(){
    this.routerA.paramMap.subscribe((params:any) => {
      this.band = params.get('band');
    }); 

  }


}

