import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NoticiaServiceService } from '../servicios/noticia-service.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit{
  listaNoticias:any[]=[];
  startIndex:any =0;
  endIndex:any = 11;

  listaMeses: string[]=[];

  constructor(private noticiaService: NoticiaServiceService,private route:Router){
  }

  ngOnInit(){
    this.getObtenerNoticias();
    this.listaMeses = this.noticiaService.getMeses() ;
  }

  getObtenerNoticias(){
    this.noticiaService.getNoticias()
    .pipe(

        /* tap( data => console.log("Lista Noticias: "+data))
 */
    ).subscribe((data)=>{
      //console.log("data noticias: ",data)
      this.listaNoticias = data
      //console.log("lista noticias: ",this.listaNoticias)
      }
      );
     
    }

    mostratMasNoticias(){
      this.endIndex +=5;
    }

    regresar(){
      this.endIndex=15;
    }

    mostrarNoticia(id:String):void{
      /* console.log("idnoticia ",id) */
      this.route.navigate([`/page-noticias/${id}`])
    }
  


	  

}
