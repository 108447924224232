import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';

const apiUrl = `${environment.other.apiUrl}`;
const headers = new HttpHeaders().set('Content-Type', 'application/json');
const options = { headers };
@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {

  ELEMENT_DATA: any = [];
  single: any = [];
  single2 = new Observable<any>;
  url: string = `${apiUrl}estadisticas`;
  urlconsolidado: string =`${apiUrl}estadisticas/consolidado`; //para spring boot es: `${apiUrl}estadisticas/consolidado`
  constructor(private _http: HttpClient) { }

  
  getEstadisticasAll(): Observable<any> {

    return this._http.get(`${this.url}/listar`);

  }

  getConsolidado(): Observable<any> {

    return this._http.get(`${this.urlconsolidado}/listar`);
  }

  getFiltrarAnios(pfirts:number,pend:number,tipo:string ):Observable<any> {
      var params = new HttpParams()
      .set('aniofirst',pfirts)
      .set('anioend',pend)
      .set('tipo',tipo)

      return this._http.get(`${this.urlconsolidado}/filtrar`,{headers,params});
  } 

  getFiltrarTodo(anio:number):Observable<any> {
    var params = new HttpParams()
    .set('anio', anio);


    return this._http.get(`${this.urlconsolidado}/filtrartodo`,{headers, params});
  }

}

export interface Consolidado {

  idconsolidado: Number;
  tipo: Number;
  subtipo: Number;  //'según: ordinaria, extraordinaria, parte policial, judicial, masiva, etc.',
  mes: Number;
  anio: Number;
  sexo: Number;
  cantidad: number;

} 