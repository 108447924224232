<div class="container h-250px">
  <h1>Directorio de correos MPL</h1>
  <p>
    <a
      href="https://drive.google.com/file/d/1U6PM_iQD89FV8DNsN_lrGfbyCx-89bxU/view"
      target="_blank"
      >Descargar correos - Fuente: Gobierno</a
    >
  </p>
  <!-- <form action="" class="formulario">
      <p>Seleccione un periodo de busquedad</p>
      <input type="date" name="periodoFirst" id="pFirst" class="form form-control" [(ngModel)]="first"  />
      <input type="date" name="periodoEnd" id="pEnd" class="form form-control"     [(ngModel)]="end"  />
      <button type="button" role="button" name="" id="" class="btn " (click)="getConvocatoriasCAS()">
        APLICAR BUSQUEDAD
      </button>
    </form> -->
</div>

<div class="container tabla">
  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    matSort
    (matSortChange)="announceSortChange($event)"
  >
    <!--  matSort (matSortChange)="announceSortChange($event)" mat-sort-header -->
    <ng-container matColumnDef="iddirectorio">
      <th mat-header-cell *matHeaderCellDef>No.</th>
      <td mat-cell *matCellDef="let element">
        {{ element.iddirectorio }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="unidadorganica">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by name"
      >
        Nombre
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element.unidadorganica
            .replaceAll("Ã", "Á")
            .replaceAll("Ã‰", "É")
            .replaceAll("Ã", "í")
            .replaceAll("Â°", "º")
            .replaceAll("Ã³", "Ó")
            .replaceAll("Ãº", "Ú")
            .replaceAll("íº", "ú")
            .replaceAll("í³", "ó")
            .replaceAll("í“", "ó")
        }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="correoinstitucional">
      <th class="w-max" mat-header-cell *matHeaderCellDef mat-sort-header>
        Correo Institucional
      </th>
      <td class="w-max" mat-cell *matCellDef="let element">
        {{ element.correoinstitucional }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="telefono">
      <th class="w-max" mat-header-cell *matHeaderCellDef>Anexo</th>
      <td class="w-max" mat-cell *matCellDef="let element">
        <a href="http://www.munilambayeque.gob.pe/presentacion/documentos/{{ element.telefono }}" target="_blank"> <img src="assets/img/pdf-ico.png" alt=""></a>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
