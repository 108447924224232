import { Component, OnInit } from '@angular/core';
import { AutoridadesServiceService } from '../servicios/autoridades-service.service';

@Component({
  selector: 'app-autoridades',
  templateUrl: './autoridades.component.html',
})
export class AutoridadesComponent  {


}
