import { Component, ViewChild } from '@angular/core';
import { ConvocatoriasGService } from '../servicios/convocatorias-g.service';
import { DataTableDirective } from 'angular-datatables';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-convocatorias-g',
  templateUrl: './convocatorias-g.component.html',
  styleUrls: ['./convocatorias-g.component.css']
})
export class ConvocatoriasGComponent {


  /* variables para la tabla: */
  @ViewChild(DataTableDirective, { static: false })
  dtOptions: DataTables.Settings = {};


  ELEMENT_DATA: ConvocatoriaGeneral[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  /* Preparamos la tabla  - columns*/
  displayedColumns: string[] = [ 'nombre', 'periodo','estado','link' ];

  //creamo el elemento datasource
  dataSource = new MatTableDataSource<ConvocatoriaGeneral>(this.ELEMENT_DATA);


  constructor(private CGService: ConvocatoriasGService, private _liveAnnouncer: LiveAnnouncer) {

  }

  getListar(): void {

    this.CGService.obtenerConcvocatorias().subscribe(
      (data) => {
        this.ELEMENT_DATA = data;
        this.dataSource = new MatTableDataSource<ConvocatoriaGeneral>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    )
  }

  ngOnInit() {
    this.getListar();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}

export interface ConvocatoriaGeneral {

  idconvocatorias: Number;
  nombre: String;
  estado: String;
  periodo: String;
  link:String;
}
