import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Importa el modulo FormsModule
import { DataTablesModule } from 'angular-datatables'; // Importa el modulo FormsModule
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { NgImageSliderModule } from 'ng-image-slider';
import { MatTableModule } from '@angular/material/table';
/* import { MatPaginator} from '@angular/material/paginator'; */
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';

import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { SliderComponent } from './slider/slider.component';
import { FooterComponent } from './footer/footer.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { HomeComponent } from './home/home.component';
import { PresentacionComponent } from './presentacion/presentacion.component';
import { AutoridadesComponent } from './autoridades/autoridades.component';
import { DirectivasComponent } from './directivas/directivas.component';
import { ObrasComponent } from './obras/obras.component';
import { SeguridadComponent } from './seguridad/seguridad.component';
import { OficinasComponent } from './oficinas/oficinas.component';
import { OrganigramasComponent } from './organigramas/organigramas.component';
import { NormasLegalesComponent } from './normas-legales/normas-legales.component';
import { PageSearchComponent } from './page-search/page-search.component';
import { PageNoticiasComponent } from './page-noticias/page-noticias.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { PageTurismoComponent } from './page-turismo/page-turismo.component';
import { PageServiciosComponent } from './page-servicios/page-servicios.component';
import { SearchServiceService } from './servicios/search-service.service';
import { NoticiaServiceService } from './servicios/noticia-service.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Slider2Component } from './slider2/slider2.component';
import { Slider3Component } from './slider3/slider3.component';
import { IframeComponent } from './iframe/iframe.component';
import { SeliderTarjetaComponent } from './selider-tarjeta/selider-tarjeta.component';
import { ConvocatoriaComponent } from './convocatoria/convocatoria.component';
import { CampaniasComponent } from './campanias/campanias.component';
import { DirectorioComponent } from './directorio/directorio.component';
import { ConvocatoriasGComponent } from './convocatorias-g/convocatorias-g.component';
import { ErrorComponent } from './error/error.component';
import { ConsolidadoComponent } from './consolidado/consolidado.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ConsolidadocompComponent } from './consolidadocomp/consolidadocomp.component';





@NgModule({
  declarations:[
    AppComponent,
    MenuComponent,
    FooterComponent,
    NoticiasComponent,
    SliderComponent,
    HomeComponent,
    PresentacionComponent,
    AutoridadesComponent,
    DirectivasComponent,
    ObrasComponent,
    SeguridadComponent,
    OficinasComponent,
    OrganigramasComponent,
    NormasLegalesComponent,
    PageSearchComponent,
    PageNoticiasComponent,
    EstadisticasComponent,
    PageTurismoComponent,
    PageServiciosComponent,
    Slider2Component,
    Slider3Component,
    IframeComponent,
    SeliderTarjetaComponent,
    ConvocatoriaComponent,
    CampaniasComponent,
    DirectorioComponent,
    ConvocatoriasGComponent,
    ErrorComponent,
    ConsolidadoComponent,
    ConsolidadocompComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    DataTablesModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    MatRadioModule,
    NgImageSliderModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    NgxChartsModule


  ],
  providers: [SearchServiceService, NoticiaServiceService, EstadisticasComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
