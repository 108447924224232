<app-menu></app-menu>

<!-- AUI VA EL CUERPO DE LAS PAGINAS -->
<!-- <app-home></app-home> -->
<!-- <app-presentacion></app-presentacion> -->
<!-- <app-autoridades></app-autoridades> -->
<router-outlet></router-outlet>
<!-- ------------------------------- -->

    <app-footer></app-footer>


