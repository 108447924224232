import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule,Routes } from '@angular/router';

/* Importo todos mis componentes */
import { HomeComponent } from './home/home.component';
import { PresentacionComponent } from './presentacion/presentacion.component';
import { AutoridadesComponent } from './autoridades/autoridades.component';
import { ObrasComponent } from './obras/obras.component';
import { SeguridadComponent } from './seguridad/seguridad.component';
import { DirectivasComponent } from './directivas/directivas.component';
import { OrganigramasComponent } from './organigramas/organigramas.component';
import { OficinasComponent } from './oficinas/oficinas.component';
import { NormasLegalesComponent } from './normas-legales/normas-legales.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { PageTurismoComponent } from './page-turismo/page-turismo.component';
import { PageServiciosComponent } from './page-servicios/page-servicios.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { PageSearchComponent } from './page-search/page-search.component';
import { PageNoticiasComponent } from './page-noticias/page-noticias.component';
import { IframeComponent } from './iframe/iframe.component';
import { SeliderTarjetaComponent } from './selider-tarjeta/selider-tarjeta.component';
import { CampaniasComponent } from './campanias/campanias.component';
import { ConvocatoriaComponent } from './convocatoria/convocatoria.component';
import { DirectorioComponent } from './directorio/directorio.component';
import { ConvocatoriasGComponent } from './convocatorias-g/convocatorias-g.component';
import { ErrorComponent } from './error/error.component';

const routes:Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {path:'home', component:HomeComponent}, //esto sirve para que no se duplique el app-root
  {path:'presentacion',component:PresentacionComponent},
  {path:'autoridades',component:AutoridadesComponent},
  {path:'obras',component:ObrasComponent},
  {path:'seguridad',component:SeguridadComponent},
  {path:'directivas',component:DirectivasComponent},
  {path:'organigramas',component:OrganigramasComponent},
  {path:'oficinas',component:OficinasComponent},
  {path:'normas-legales',component:NormasLegalesComponent},
  {path:'noticias',component:NoticiasComponent},
  {path:'page-turismo',component:PageTurismoComponent },
  {path:'page-servicios/:id',component:PageServiciosComponent},
  {path:'estadisticas/consolidados',component:EstadisticasComponent},
  {path:'page-search/:busq',component:PageSearchComponent},
  {path:'page-noticias/:id',component:PageNoticiasComponent},
  {path:'gobierno/:band',component:IframeComponent},
  {path:'selider-tarjeta', component:SeliderTarjetaComponent},
  {path:'campanias',component:CampaniasComponent},
  {path:'convocatoria',component:ConvocatoriaComponent},
  {path:'directorio',component:DirectorioComponent},
  {path:'convocatoriaspublica',component:ConvocatoriasGComponent},
  {path:'error-404',component:ErrorComponent},
  {path:'**',component:ErrorComponent}

]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
