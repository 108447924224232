import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';

const apiUrl = `${environment.other.apiUrl}`;
@Injectable({
  providedIn: 'root'
})
export class ConvocatoriasGService {

  url =`${apiUrl}convocatorias`; 
  constructor(private _http:HttpClient) { }


  obtenerConcvocatorias():Observable<any>{
    return this._http.get(`${this.url}/listargeneral`);
  }

}
 