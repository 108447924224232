<main class="px-4 py-5">
  <!-- MENU LATERAL -->

  <div class="my-5 py-5 w-480">
    <div class="container">
      <app-slider3></app-slider3>
      <section class="mt-2 seccion_noticias">
        <h3 class="">MÁS NOTICIAS</h3>
        <article class="noticias_items">
          <div  *ngIf="listaNoticias">
            <li *ngFor="let noticia of listaNoticias.slice(0,5)">
              <p  class="noticia_title" >
                {{noticia.titulo.replaceAll('Ã‰', 'É')
                .replaceAll("Ã","Í")
                .replaceAll('Ã“', 'Ó') 
                .replaceAll("Ãš","Ú")
                .replaceAll('Ã‘','Ñ')
                .replaceAll("NÂº ","Nº")
                .replaceAll("Â°","º")
                }}
              </p>
              <div class="noticia_footer">
                <p>{{ Meses[noticia.mes] }} {{noticia.dia }},{{ noticia.anio }}</p>
                <a role="button" (click)="mostrarNoticia(noticia.idnoticia)" class=""> Leer Más ></a>
              </div>
            </li>
          </div>
        </article>
      </section>
    </div>
  </div>

  <!-- FIN DE MENU LATERAL -->

  <div class="container ms-3 me-2" id="featured-3">
    <div class="container_title ">
      <h2 class="px-4 py-3 title"><span></span>{{title.toUpperCase()}} | {{busq}}</h2>
      <form class="from_nav_search ms-1">
        <input type="search" name="buscar" id="buscar" [(ngModel)]="busq" placeholder="  buscar juntas vecinales.." >
        
        <i
          type="button"
          class="fa-solid fa-magnifying-glass ms-1 me-3"  (click)=" getBuscar()"
        ></i>
        <!-- routerLink="/page-search/lambayeque" -->

        <!-- [(ngModel)]="value_input_search"   #newItem-->
        <!-- (click)="addNewItem(newItem.value)"  -->
      </form>
    </div>
    <div class="row d-flex g-4 py-5 px-2 row-cols-1 row-cols-lg-3">
      <div class="feature col tarjeta" *ngFor="let servicio of resultado.slice(0,5) ">
        <!-- <p class="detalle">SEGURIDAD</p> -->
        <div class="bg-gradient img_container_servicio">
          <img src="assets/img/logos/11.png" alt="" />
        </div>
        <div class="tarjeta_body">
          <h3 class="">
            {{servicio.titulo.replaceAll('Ã‰', 'É')
            .replaceAll("Ã","Í")
            .replaceAll('Ã“', 'Ó') 
            .replaceAll("Ãš","Ú")
            .replaceAll('Ã‘','Ñ')
            .replaceAll("NÂº ","Nº")
            .replaceAll("Â°","º")
            }}
          </h3>
          <p [innerHTML]='servicio.brevedescripcion.split(" ").slice(0,30).join(" ") + "..."'>
          </p>
        </div>
        <div class="footer_tarjeta">
          <p>{{ Meses[servicio.mes] }} {{ servicio.dia }},{{ servicio.anio }}</p>
          <a role="button" (click)="mostrarNoticia(servicio.idnoticia)" class=""> Leer Más </a>
        </div>
      </div>
      
      
    </div>
  </div>

  <!-- MENU LATERAL -->
</main>
