import { Component, Input } from '@angular/core';
import { SearchServiceService } from '../servicios/search-service.service';
import { NoticiaServiceService } from '../servicios/noticia-service.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-search',
  templateUrl: './page-search.component.html',
  styleUrls: ['./page-search.component.css']
})
export class PageSearchComponent {

  startIndex:any =0;
  endIndex:any = 0;
  listaMeses: string[]=[];
  valorBusquedad:any ="" ;
  resultados:any[]=[];
  private routeSub: Subscription = new Subscription();
  //ruta para mostrar noticia unica:
 

  constructor( private router: ActivatedRoute,private  route:Router,
     private noticiaService:NoticiaServiceService, private searchService:SearchServiceService){
  }
  ngOnInit() {
    this.search();
  }
  search(){
    
    this.routeSub =this.router.paramMap.subscribe(params => {
      this.valorBusquedad = params.get('busq');
      /* console.log( "busuusu:  ",this.valorBusquedad ); */
      this.getObtenerBusquedad(this.valorBusquedad );
    });
   
  }
  getObtenerBusquedad(busq:any){
    this.listaMeses = this.noticiaService.getMeses() ;
    this.searchService.getBusquedadNoticias(busq)
        .subscribe(
          (result) => {
            this.resultados = result;
            
            this.endIndex = (this.resultados.length >14) ? 14:this.resultados.length 
          }
        );
  }
  mostratMasNoticias(){
    this.endIndex +=5;
  }

  regresar(){
    this.endIndex=15;
  }

  /* Metodopara mostrar noticia seleccionada */
  mostrarNoticia(id:String):void{
   /*  console.log("idnoticia ",id) */
    this.route.navigate([`/page-noticias/${id}`])
  }

  
  
    
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
  

}
