

<div class="container mt-5">
  <mat-tab-group dynamicHeight >
    <mat-tab label="Defunciones">
      <div class="example-small-box mat-elevation-z4 defunciones_tabla ">
          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            matSort
            (matSortChange)="announceSortChange($event)"
          >
            <!--  matSort (matSortChange)="announceSortChange($event)" mat-sort-header -->
            <ng-container matColumnDef="acta">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                sortActionDescription="Sort by acta"
              >
                Acta
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.acta }}
              </td>
            </ng-container>
                 <!-- Name Column -->
            <ng-container matColumnDef="fecha">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                sortActionDescription="Sort by fecha"
              >
                Fecha
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.fecha | slice:0:10 }}
              </td>
            </ng-container>
  
       
            <ng-container matColumnDef="apellidos">
              <th class="w-max" mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</th>
              <td
                class="w-max"
                mat-cell
                *matCellDef="let element"
              >{{element.apellidos}}</td>
            </ng-container>
  
            <!-- Weight Column -->
            <ng-container matColumnDef="nombres">
              <th class="w-max" mat-header-cell *matHeaderCellDef mat-sort-header>
                Nombres
              </th>
              <td class="w-max" mat-cell *matCellDef="let element">
                {{ element.nombres }}
              </td>
            </ng-container>
  
            <!-- Symbol Column -->
            <ng-container matColumnDef="sexo">
              <th class="w-max" mat-header-cell *matHeaderCellDef>Sexo</th>
              <td class="w-max" mat-cell *matCellDef="let element">
                <p *ngIf=" element.sexo ==1 "> Masculino</p>
                <p *ngIf=" element.sexo ==2 "> Femenino</p>
              </td>
            </ng-container>
  
            <!-- Symbol Column -->
            <ng-container matColumnDef="documento">
              <th class="w-max" mat-header-cell *matHeaderCellDef  mat-sort-header>Documento</th>
              <td class="w-max" mat-cell *matCellDef="let element">
                 {{element.documento}}
              </td>
            </ng-container>
  
            <!-- Symbol Column -->
            <ng-container matColumnDef="subtipo">
              <th  mat-header-cell *matHeaderCellDef>subtipo</th>
              
              <td mat-cell *matCellDef="let element">
               
                  <p *ngIf=" element.subtipo==1">Ordinario</p>
                  <p *ngIf=" element.subtipo==2">Extraordinaria</p>
                  <p *ngIf=" element.subtipo==3">Parte policial</p>
                  <p *ngIf=" element.subtipo==4">Judicial</p>
                  <p *ngIf=" element.subtipo==5">Masiva</p>
                  <!-- <p *ngIf=" element.subtipo==5">Otros</p> -->
             </td>
            </ng-container>
  
            <!-- Symbol Column -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </div>
    </mat-tab>
  
    <mat-tab label="Estadisticas por Tipo">
      <div class="">

        <app-consolidado></app-consolidado>
      </div>
    </mat-tab>

    <mat-tab label="Comparativas por años">
      <div class="">
        
        <app-consolidadocomp></app-consolidadocomp>
      </div>
    </mat-tab>
  </mat-tab-group>
  
</div>
