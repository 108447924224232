import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NoticiaServiceService } from '../servicios/noticia-service.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import * as AOS from 'aos';
/* import 'aos/dist/aos.css'; */

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{

  listaNoticias:any;
  urlRecurso: string = 'https://www.youtube.com/embed/TbO88ywChf0';
  urlSanitizada: SafeResourceUrl = '';
  constructor (private serviceNoticias:NoticiaServiceService,private route:Router,private sanitizer: DomSanitizer){
    
  }


  

  
    
  
  ngOnInit(){
      this.obtenerNoticias();
      this.urlSanitizada = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRecurso);  
      AOS.init();
      window.addEventListener('load',AOS.refresh);
      
  }

  public obtenerNoticias(){
    this.serviceNoticias.getNoticias().subscribe(
      (data) => {this.listaNoticias = data
      this.listaNoticias = this.listaNoticias
    }
    );
  }

  mostrarNoticia(id:String):void{
    console.log("idnoticia ",id)
    this.route.navigate([`/page-noticias/${id}`])
  }

}
