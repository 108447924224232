import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-turismo',
  templateUrl: './page-turismo.component.html',
  styleUrls: ['./page-turismo.component.css']
})
export class PageTurismoComponent {

  band:Number ;


  constructor(private router: Router) {this.band =0;}

  ngOnInit(){

  }

  cerrar(){
    this.band = 0;
  }

  mostrarDestinos(){

    this.band = 1;
    this.irASeccion1();
  }
  mostrarPlatos(){
    this.band = 2;
  }
  mostrarBailes(){
    this.band = 3;
  }
  mostrarFiestas(){
    this.band = 4;
  }



  irASeccion1() {
    const element = document.querySelector('#destinos');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
 

 
}
