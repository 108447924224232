<style>
  h1 {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    color: #003c7b;
    display: block;
  }
  select {
    font-size: 16px;
    border-radius: 5px;
    border: 2px solid #ccc;
    padding: 3px 10px;
    box-sizing: border-box;
    color: black;
    /* -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; */
  }

  select:focus {
    border-color: #007bff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  select option {
    background-color: white;
    color: black;
  }
  .width-lg-300px {
    width: 300px;
  }
  .width-lg-600px {
    width: 600px;
  }
  .width-lg-200px {
    width: 200px;
  }
</style>
<div class="container mb-5">
  <div class="d-flex align-items-center justify-content-center mt-5">
    <!-- <div class=">.col-md-4</div> -->
    <!-- <div class="col-md-4 offset-md-4">.col-md-4 .offset-md-4</div> -->
    <h1 class="col-sm-5 col-md-6">Normas Legales</h1>
    <div class="ps-5">
      <label for="anio">Anio</label>
      <select  class="col-sm-5 width-lg-200px " aria-label="" [(ngModel)]="anioSelec" (ngModelChange)="onOptionAnioChange()">
        <option value="">-- selecciona --</option>
        <option value="2023">2023</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>
        <option value="2017">2017</option>
        <option value="2016">2016</option>
        <option value="2015">2015</option>
        <option value="2014">2014</option>
        <option value="2013">2013</option>
        <option value="2012">2012</option>
        <option value="2011">2011</option>
      </select>
    </div>
    <div class="ps-5">
        <label for="trimestre">Trimestre</label>
        <select  class="col-sm-5 width-lg-200px "  [disabled]="!anioSelec"  [(ngModel)]="trimestreSelec"
        aria-label="Default select example" [ngStyle]="{'opacity':anioSelec!= ''?'1':'0.5' }"  (ngModelChange)="onChangeTrimestre()"
        > 
        <!-- [(ngModel)]="trimestreSelec"  
        [disabled]="!anioSelec" [ngClass]="anioSelec == ''?'clase_deshabilitar':''" -->
        <!-- -->
        <!-- (ngModelChange)="tipoSelect.disabled = !trimestreSeleccionado" -->
            <option value="">-- Selecciona --</option>
          <option value="1">Trimestre I</option>
          <option value="2">Trimestre II</option>
          <option value="3">Trimestre III</option>
          <option value="3">Trimestre IV</option>

        </select>
      </div>
  </div>
  <div class="mt-3 ps-3">
    <label for="tipo">Tipo</label>
    <select  class="col-sm-5 width-lg-300px ms-3" aria-label="" [disabled]="!trimestreSelec"  [(ngModel)]="tipoSelect"
      [ngStyle]="{'opacity':trimestreSelec != ''?'1':'0.5'}"   > 
      <option value="">-- Selecciona --</option>
      <option value="1">ACUERDO DE CONCEJO</option>
      <option value="2">DECRETO DE ALCALDIA</option>
      <option value="3">FE DE ERRATAS</option>
      <option value="1">ORDENANZA MUNICIPAL</option>
      <option value="2">RESOLUCION DE GERENCIA</option>
      <option value="3">RESOLUCION DE ALCALDIA</option>
      
    </select>
  </div>
  
  <div class="mt-5 mb-5 pt-4">
    <table datatable class="row-border hover " [dtOptions]="dtOptions"  [dtTrigger]="dtTrigger"  id="example" name="example" >  
      <thead>
        <tr class="">
          <th class="uno">nombre</th>
          <th class="dos">tipo</th>
          <th class="tres">descripciòn</th>
          <th class="cuatro">fecha</th>
          <th class="cinco">link</th>
        </tr>
      </thead>
      <tbody *ngIf="filtro != null" >
        <tr *ngFor="let documento of filtro" >
          <td >{{documento.nombre_doc}}</td>
          <td >{{documento.tipo_doc}}</td>
          <td >
            <span *ngIf="documento.descripcion_doc != null ">{{documento.descripcion_doc.split(" ").slice(0,5).join(" ")}}</span>
            <span *ngIf="documento.descripcion_doc == null ">-------</span>
            
          </td>
          
          <td > {{documento.fecha_doc}}   </td>
          <td ><a href="{{documento.link_doc}}">Ver</a></td>
        </tr>

      </tbody>
    </table>
  </div>
  
</div>
