<footer class="pie-pagina">
  <div class="grupo-1">
    <div class="box">
      <div class="d-block">
        <h6>Lambayeque ciudad evocadora, benemérita y generosa</h6>
        <figure>
          <a href="">
            <img src="assets/img/LOGO_MUNI.png" alt="Logo" />
          </a>
        </figure>
        <h6>Capital del turismo</h6>
      </div>
    </div>
    <div class="box">
      <h2>Enlaces directos</h2>
      <p>
        <a
          class="enlaces_link"
          href="https://reclamos.servicios.gob.pe/?institution_id=284"
          target="_blank"
          >Libro de reclamaciones</a
        >
      </p>
      <!-- <p><a class="enlaces_link" href="https://www.munilambayeque.gob.pe/intranet/login.php" target="_blank">Intranet</a></p> -->
      <p>
        <a
          class="enlaces_link"
          href="https://intranet.munilambayeque.gob.pe/intranet/mesadepartes"
          target="_blank"
          >Mesa de partes virtual</a
        >
      </p>
      <p>
        <a
          class="enlaces_link"
          href="https://intranet.munilambayeque.gob.pe/intranet/"
          target="_blank"
          >Sistema de trámite</a
        >
      </p>
      <p>
        <a
          class="enlaces_link"
          href="https://intranet.munilambayeque.gob.pe/intranet/consultatutramite"
          target="_blank"
          >Seguimiento de tu trámite
        </a>
      </p>
      <p>
        <a class="enlaces_link" routerLink="/gobierno/portal-de-transparencia"
          >Portal de transparencia</a
        >
      </p>
    </div>
    <div class="box">
      <h2>SIGUENOS</h2>
      <div class="red-social">
        <a
          class="link_redes"
          href="https://www.facebook.com/MunicipalidadLambayeque/?fref=ts"
          target="_blank"
          ><i class="fa-brands fa-facebook"></i
        ></a>
        <a
          class="link_redes"
          href="https://www.instagram.com/munilambayeque/?utm_source=ig_embed&ig_rid=e8ae8521-c9b7-4fd0-84cc-457ffaa3941e"
          target="_blank"
          ><i class="fa-brands fa-instagram"></i
        ></a>
        <a
          class="link_redes"
          href="https://www.youtube.com/channel/UCWg1Yk1aq2E4H9diBhbyJOw"
          target="_blank"
          ><i class="fa-brands fa-youtube"> </i
        ></a>
      </div>
      <h5>
        <a
          href="https://www.gob.pe/munilambayeque"
          target="_blank"
          class="color-white"
          >gob.pe/munilambayeque</a
        >
      </h5>
     
    </div>
  </div>
  <div class="grupo-2">
    <small
      >&copy; 2023 <b>Municipalidad Provincial de Lambayeque</b> - Derechos
      Reservados.
    </small>
    <small>
      <b>Ubicanos en: </b>
      Av. Bolivar N° 400
    </small>
  </div>
</footer>

