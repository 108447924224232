<main class="container pt-5 mb-5">
    
    <div class="row g-5 ">
        
        <div class="col-md-8">
        <div class="navegacion">
            <p><a routerLink="/home">Home</a> > <a routerLink="/noticias">Noticias</a> > Page-noticias   </p>
        </div>
        <div class="container_logo">
            <img src="assets/img/noticias_logo.png" alt="">
            <p class="ms-2">Noticias</p>
        </div>
        <div class="enlaces-externos container_footer">
            <ul>
              <!-- <a href="#">Libro de reclamaciones</a> -->
              <a href="https://www.facebook.com/MunicipalidadLambayeque/?fref=ts"><i class="fa-brands fa-facebook"></i></a>
              <a href="https://www.instagram.com/munilambayeque/?utm_source=ig_embed&ig_rid=e8ae8521-c9b7-4fd0-84cc-457ffaa3941e" target="_blank"><i class="fa-brands fa-instagram"></i></a>
              <a href="https://www.youtube.com/channel/UCWg1Yk1aq2E4H9diBhbyJOw"><i class="fa-brands fa-youtube"></i></a>
            </ul>
          </div> 


        
          <div *ngIf="resultado">
            <h3 class="pb-4 mb-4 fst-italic border-bottom">
              {{resultado.titulo.replaceAll('Ã','Á')
              .replaceAll('Ã‰', 'É')
              .replaceAll("Ã","Í")
              .replaceAll('Ã“', 'Ó') 
              .replaceAll("Ãš","Ú")
              .replaceAll('Ã‘','Ñ')
              .replaceAll("NÂº ","Nº")
              .replaceAll("Â°","º")
              }}
            <!-- Ã  -->
            </h3>
            <div>
              <!-- <img  src="assets/img/logos/noticias2.png" alt="noticia seleccionada imagen" /> -->
              <article class="blog-post">
                <!-- <h5 class="blog-post-title mb-1" role='button'> </h5> -->
                
                <p class="blog-post-meta">            
                  <span>{{resultado.anio}}/{{resultado.mes}}/{{resultado.dia}}</span>
                </p>
                <!-- ,  by <a href="#">Mark</a> -->
                  <p  *ngIf=' resultado.descripcion !="" ' [innerHTML]="resultado.descripcion">
                  </p>
                  <p  *ngIf=' resultado.descripcion =="" ' [innerHTML]="resultado.brevedescripcion">
                  </p>
              </article>
              <hr/>
            </div>
          </div>
        </div>
    
        <!-- Barra Lateral -->
        <div class="col-md-4">
          <div class="position-sticky" style="top: 2rem">
            <div class="p-4 mb-3 bg-warning  rounded">
              <!-- bg-light -->
              <div class="mb-0">

                <app-slider3></app-slider3>


              </div>
            </div>
    
            <div class="p-4">
              <h4 class="fst-italic">Lo más visto</h4>
              <ol class="list-unstyled mb-0">
                
                
                <li><a href="https://www.gob.pe/23228-solicitar-certificado-domiciliario?child=15399">> Solicitar certificado domiciliario a la Municipalidad Provincial de Lambayeque</a></li>
                <li><a href="https://www.gob.pe/institucion/munilambayeque/pages/15404-obtener-certificado-de-salubridad-para-locales-comerciales-en-lambayeque">> Obtener certificado de salubridad para locales comerciales. </a></li>
                <li><a href="https://www.gob.pe/20414-obtener-copia-certificada-de-una-partida-o-acta-de-nacimiento-matrimonio-o-defuncion?child=16363">> Solicitar copia certificada de partida de nacimiento, matrimonio o defunción </a></li>
                <li><a href="https://www.gob.pe/20399-solicitar-acceso-a-la-informacion-publica?child=15479">> Solicitar información pública a la Municipalidad Provincial de Lambayeque</a></li>
                <li><a href="https://reclamos.servicios.gob.pe/?institution_id=284" target="_blank">> Libro de reclamaciones</a></li>
                <li><a routerLink="/gobierno/TUPA">> TUPA</a></li>
                <li><a routerLink="/gobierno/informacion-publica">> Acceso a la información pública</a></li>
              </ol>
            </div>
    
           <!--  <div class="p-4">
              <h4 class="fst-italic">Redes Sociales y Contacto</h4>
              <ol class="list-unstyled">
                <li><a href="#">Gmail</a></li>
                <li><a href="#">Youtube</a></li>
                <li><a href="#">Facebook</a></li>
              </ol>
            </div> -->
          </div>
        </div>
    </div>
</main>
