<app-slider></app-slider>
<!-- <app-selider-tarjeta></app-selider-tarjeta> -->
<!-- Enlaces -->
<div class="bg-gris   " >
  <section class="container container-presindente-banner  ">
    <div class="container-img-presi me-3 p-3" >
      <img src="assets/img/funcionarios/percy_ramos2.png" alt="img " />          
    </div>
    <div class="link_presindente_banner" data-aos="fade-right">
      <section class="">
        <p class="container-link-gob_img mb-3 d-flex align-items-center" href="#">
          <img src="assets/img/ICONOS-CONTACTOS-EN-LINEA-e1590103563916.png" alt="img " />
          <span>Contactos en línea  <br>
            074-281911
            | 074-504787</span>
        </p>
        <a class="container-link-gob_img mb-3  d-flex align-items-center" routerLink="/gobierno/TUPA">
          <img src="assets/img/icono-tupa.png" alt="img " />
          <span>TUPA</span>
        </a>
        <a class="container-link-gob_img d-flex align-items-center" 
        href="https://intranet.munilambayeque.gob.pe/intranet/consultatutramite" target="_blank">
          <img src="assets/img/icono-tramites.png" alt="img " />
          <span>Consulta estado de tu trámite</span>
        </a>

        <a class="container-link-gob_img d-flex align-items-center"
          href="https://intranet.munilambayeque.gob.pe/intranet/mesadepartes" target="_blank" >
          <img src="assets/img/icono-tributos-municipales.png" alt="img " />
          <span>Mesa de partes virtual</span>
        </a>
        <a class="container-link-gob_img d-flex align-items-center" 
        href="https://intranet.munilambayeque.gob.pe/" target="_blank">
          <img src="assets/img/icono-mesapartes.png" alt="img " />
          <span>Intranet trámite</span>
        </a>
        <a class="container-link-gob_img mb-3  d-flex align-items-center"
        target="_blank"
          href="https://www.gob.pe/institucion/munilambayeque/tramites-y-servicios">
          <img src="assets/img/ICONO-PAGOS-EN-LINEA-2-e1588973855543.png" alt="img" />
          <span>Ver más Tramites</span>
        </a>
        <!-- <a class="container-link-gob_img mb-3  d-flex align-items-center"
        target="_blank"
          href="https://www.gob.pe/institucion/munilambayeque/informes-publicaciones/2801103-convocatoria-de-personal-bajo-la-modalidad-del-d-l-n-728">
          <img src="assets/img/ICONO-PAGOS-EN-LINEA-2-e1588973855543.png" alt="img " />
          <span>Trabaja con Nostros</span>
        </a> -->
      </section>
        
    </div>
  </section>
</div>

<!-- Noticias -->
<div class="container mt-3 container_noticias " data-aos="fade-right">
  <div class="lastest-container">
    <section class="latest-news">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h2 class="noticias-title">Últimas noticias</h2>
        <a role="button" target="_blank" href="https://www.gob.pe/institucion/munilambayeque/noticias" class="ms-5 btn enlace_mas_noticias">Todas las noticias</a>
      </div>
      <div  class="container_news_lsl" >
    
        <div class="news-item mt-3 news-item2" >
          <!-- <img src="assets/img/logos/noticias_img_home.jpg" alt="Título de la noticia 1" />   *ngFor="let noticia of listaNoticias.slice(0,3  )"-->
          <img  src="assets/img/logos/standard_53171467024_1f6bf2f220_k.jpg"  alt="Título de la noticia 1" /> 
          <div class="news-item-content">

            <h3 >Lambayeque: Mininter suscribe pacto por la seguridad ciudadana con autoridades locales, 
              sector privado y la academia</h3>
            <p>38 alcaldes distritales y provinciales asumieron el ...
            </p>
          </div>
          <div class="container_btn_more2">
            <div></div>
            <!-- <a role="button" class="btn_readmore border_btn" (click)="mostrarNoticia(noticia.idnoticia)">Leer más</a> -->
            <a role="button" class="btn_readmore border_btn"
             href="https://www.gob.pe/institucion/mininter/noticias/830707-lambayeque-mininter-suscribe-pacto-por-la-seguridad-ciudadana-con-autoridades-locales-sector-privado-y-la-academia" target="_blank">Explorar</a> 
            <div></div>
          </div>
        </div>
        <div class="news-item mt-3 news-item2"  >
          <img  src="assets/img/logos/standard_Reapertua BiBlioteca  - MPL.jpeg"  alt="Título de la noticia 1" /> 
          <div class="news-item-content">
            <h3 >Ceremonia de Reapertura de Biblioteca</h3>
            <p>
              La M.P.L. a traves de su Área de Biblioteca Educacdión Cultura, hace de conocimiento la
               CEREMONIA de Reapertura de la Biblioteca "Emiliano Niño Pastor" ....
            </p>
          </div>
          <div class="container_btn_more2">
            <div></div>
            <a role="button" class="btn_readmore border_btn" 
            href="https://www.gob.pe/institucion/munilambayeque/noticias/837270-ceremonia-de-reapertura-de-biblioteca" target="_blank" >Explorar</a> 
            <div></div>
          </div>
        </div>
       
      </div>
      <div  class="container_news_lsl" >
    
        <div class="news-item mt-3 news-item2" >
          <!-- <img src="assets/img/logos/noticias_img_home.jpg" alt="Título de la noticia 1" />   *ngFor="let noticia of listaNoticias.slice(0,3  )"-->
          <img  src="assets/img/logos/standard_ONP.jpg"  alt="Título de la noticia 1" /> 
          <div class="news-item-content">
   
            <h3 >Acuerdan abrir un nuevo Punto de Atención de la ONP en la región Lambayeque</h3>
            <p>Jefe de la ONP se reunió con autoridades de la Municipalidad Provincial de Lambayeque. 
              También supervisó atención de asegurados en Chiclayo y Pomalca
            </p>
            <!-- <p [innerHTML]='noticia.brevedescripcion.replaceAll("âœ…","")'></p> -->
            
          </div>
          <div class="container_btn_more2">
            <div></div>
            <!-- <a role="button" class="btn_readmore border_btn" (click)="mostrarNoticia(noticia.idnoticia)">Leer más</a> -->
            <a role="button" class="btn_readmore border_btn" href="https://www.gob.pe/institucion/onp/noticias/758206-acuerdan-abrir-un-nuevo-punto-de-atencion-de-la-onp-en-la-region-lambayeque" target="_blank">Explorar</a> 
            <div></div>
          </div>
        </div>
        <div class="news-item mt-3 news-item2"  >
          <img  src="assets/img/logos/standard_Declaracion Jurada 2022.jpeg"  alt="Título de la noticia 1" /> 
          <div class="news-item-content">
            <h3 >Presentación de Declaración Jurada Anual 2022 - SENCICO</h3>
            <p>Información proporcionada por SENCICO para su publicación.
            </p>
          </div>
          <div class="container_btn_more2">
            <div></div>
            <a role="button" class="btn_readmore border_btn" href="https://www.gob.pe/institucion/munilambayeque/noticias/742191-presentacion-de-declaracion-jurada-anual-2022-sencico" target="_blank">Explorar</a> 
            <div></div>
          </div>
        </div>
       
      </div>
      <!-- <div  *ngIf="listaNoticias"  >
        <div class="news-item" *ngFor="let noticia of listaNoticias.slice(0,4)" >
     
          <img  src="assets/img/logos/noticias_img_home.jpg"  alt="Título de la noticia 1" /> 
          <div class="news-item-content">
            <h3 (click)="mostrarNoticia(noticia.idnoticia)" >{{noticia.titulo.replaceAll('Ã','Á')
                                .replaceAll('Ã‰', 'É')
                                .replaceAll("Ã","Í")
                                .replaceAll('Ã“', 'Ó') 
                                .replaceAll("Ãš","Ú")
                                .replaceAll('Ã‘','Ñ')
                                .replaceAll("NÂº ","Nº")
                                .replaceAll("Ã³","ó")
                                .replaceAll("&deg;","º")
                                .replaceAll("Âº","º")
                }}
                
              </h3>
            <p [innerHTML]='noticia.brevedescripcion.replaceAll("âœ…","")'></p>
             
          </div>
          <div class="container_btn_more">
            <a role="button" class="btn_readmore" (click)="mostrarNoticia(noticia.idnoticia)">Leer más</a> 
            <span>{{noticia.dia }}/{{noticia.mes}}/{{noticia.anio}}</span><i class="far fa-heart"></i>
          </div>
        </div>
        
      </div> -->
    </section>
  </div>
  <div class="link_gob" data-aos="fade-right"  data-aos-easing="ease-out-cubic" data-aos-duration="1500">
    <div>
      <iframe  class="e2e-iframe-untrusted-src" [src]="urlSanitizada"  frameborder="0"></iframe>
    </div>
    <section>
      <h2 class="noticias-title mt-3">Contáctanos</h2>
      <a class="container-link-gob_img" href="https://reclamos.servicios.gob.pe/?institution_id=284">
        <img src="assets/img/logo_libroreclamacionesqr.jpg" alt="">
      </a>
      <div class="container-link-gob_img" href="">
        <img src="assets/img/defensa_civil.png" alt="" />
      </div>
      <a class="container-link-gob_img" href="">
        <img src="assets/img/centro_monitoreo.png" alt="" />
      </a>
      <div class="container-link-gob_img" href="">
        <img src="assets/img/comisaria.png" alt="" />
      </div>
      <div class="container-link-gob_img" href="" data-aos="flip-left"  data-aos-duration="1500">
        <img src="assets/img/Orientacion_ciudadano.png" alt="" />
      </div>
      
      <!-- <a class="container-link-gob_img" href="">
        <img src="assets/img/plataforma-unica.png" alt="" />
      </a>
      <a class="container-link-gob_img" href="">
        <img src="assets/img/logo_controlinterno.png" alt="" />
      </a> -->
      
    </section>
  </div>
</div>
<div class="container wx mt-5 "  data-aos="fade-right"  data-aos-duration="1000">
  <h4 class="title_fotos " >Más Fotos</h4>
</div>
<div class="my-3 bg-gris ">
  <app-selider-tarjeta></app-selider-tarjeta>
</div>
<div class="container wx mt-5 "  data-aos="fade-right"  data-aos-duration="1000">
  <h4 class="title_fotos" >Lambayeque segura y limpia </h4>
  <div  class="container_news_lsl" >
    
    <div class="news-item mt-3 news-item2" >
      <!-- <img src="assets/img/logos/noticias_img_home.jpg" alt="Título de la noticia 1" />   *ngFor="let noticia of listaNoticias.slice(0,3  )"-->
      <img  src="assets/img/slider/seguridad.jfif"  alt="Título de la noticia 1" /> 
      <div class="news-item-content">
        <!-- <h3  (click)="mostrarNoticia(noticia.idnoticia)">{{noticia.titulo.replaceAll('Ã','Á')
                            .replaceAll('Ã‰', 'É')
                            .replaceAll("Ã","Í")
                            .replaceAll('Ã“', 'Ó') 
                            .replaceAll("Ãš","Ú")
                            .replaceAll('Ã‘','Ñ')
                            .replaceAll("NÂº ","Nº")
                            .replaceAll("Â°","º")
            }}
        </h3> -->
        <h3 >Lambayeque segura</h3>
        <p>En conjunto con la policia y las juntas vecinales lograremos una ciudad más segura. Fomentemos la 
          convivencia pacífica y el bienestar de todos los ciudadanos.
        </p>
        <!-- <p [innerHTML]='noticia.brevedescripcion.replaceAll("âœ…","")'></p> -->
        
      </div>
      <div class="container_btn_more2">
        <div></div>
        <!-- <a role="button" class="btn_readmore border_btn" (click)="mostrarNoticia(noticia.idnoticia)">Leer más</a> -->
        <a role="button" class="btn_readmore border_btn" routerLink="/seguridad">Explorar</a> 
        <div></div>
      </div>
    </div>
    <div class="news-item mt-3 news-item2"  >
      <img  src="assets/img/slider/limpieza.jpg"  alt="Título de la noticia 1" /> 
      <div class="news-item-content">
        <h3 >Ambiente</h3>
        <p>Pequeñas acciones individuales pueden tener un gran impacto 
          colectivo en la preservación de nuestro planeta. Cuidemos nuestra ciudad.
        </p>
      </div>
      <div class="container_btn_more2">
        <div></div>
        <a role="button" class="btn_readmore border_btn" routerLink="/page-servicios/5">Explorar</a> 
        <div></div>
      </div>
    </div>
    <div class="news-item mt-3 news-item2"  >
      <img  src="assets/img/slider/turismo.jpg"  alt="Título de la noticia 1" /> 
      <div class="news-item-content">
        <h3 >Turismo</h3>
        <p>El turismo es una fuente de enriquecimiento cultural y económico, fomentando el 
          intercambio y la comprensión entre diferentes constumbres de la región Lambayeque.
        </p>
      </div>
      <div class="container_btn_more2">
        <div></div>
        <a role="button" class="btn_readmore border_btn" routerLink="/page-turismo">Explorar</a> 
        <div></div>
      </div>
    </div>
  </div>
</div>

<!-- MAPA LAMBAYEQUE -->
<div class="container wx ">
  <h4 class="title_fotos" >Mapa</h4> 
  <section class="mt-4 mb-4 d-flex justify-content-center ">
    <iframe 
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63399.986325330814!2d-79.94294425695023!3d-6.708771208308921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x904ced92aa289a0b%3A0xf0aaf8e50d58774b!2sLambayeque!5e0!3m2!1ses!2spe!4v1680922820690!5m2!1ses!2spe" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
  </iframe>
  </section>
</div>

<!-- Ma enlaces -->
<div class="link_gene wx ">
  <h4 class="title_fotos" >Visita tambien</h4> 
    <section class="row d-flex justify-content-center flex-wrap ">
      
      
      <a class="col-sm-3 col-lg-3 col-12 container-link-gob_img " href="https://www.rcc.gob.pe/2020/"  target="_blank">
        <img src="assets/img/logo_reconstruccion_cambios.png" alt="" >
      </a>
      <a class="col-sm-3  col-lg-3 col-6 container-link-gob_img d-flex justify-content-center " href="https://www.gob.pe/mef" target="_blank">
        <img src="assets/img/logo_MEF.png" alt="img Ministerio de Econimia y Finanzas" >
      </a>
      <a class="col-sm-3 col-lg-3 col-12 container-link-gob_img " href="https://www.sunat.gob.pe/index.html" target="_blank">
        <img src="assets/img/logo_sunat.jpg" alt="" />
      </a>
      <a class="col-sm-3 col-lg-3 col-12 container-link-gob_img" href="https://elperuano.pe/" target="_blank">
        <img src="assets/img/logo_elperuano.jpg" alt=""  />
      </a>
      

      <a class="col-sm-3 col-lg-3 col-12 container-link-gob_img "  href="https://www.senati.edu.pe/?nocache=1"
      target="_blank" >
        <img src="assets/img/senati.png" alt="" />
      </a>
      <!-- Enlace interno --> 
      <a class="col-sm-3 col-lg-3 col-12 container-link-gob_img "  href="https://reclamos.servicios.gob.pe/?institution_id=284"
      target="_blank" >
        <img src="assets/img/LIBRO_RECLAMACIONES_DIGITAL_LOGO2.jpg" alt="" />
      </a>
      <!-- <a class="col-sm-3 col-lg-3 col-12 container-link-gob_img " href="">
        <img src="assets/img/Meta3401.png" alt="" />
      </a> -->
      <a class="container-link-gob_img  col-sm-3  col-lg-3 col-12" href="https://cdn.www.gob.pe/uploads/document/file/3289975/INFORME%20PRESUPUESTO%20PARTICIPATIVO2023.pdf?v=1656345640" target="_blank">
        <img src="assets/img/logo_presupuesto_particpativo.png" alt="" />
      </a>
      <!-- <a class="container-link-gob_img  col-sm-3  col-lg-2 col-12" href="">
        <img src="assets/img/defensacivil.png" alt="" />
      </a> -->
      <a class="col-sm-3 col-lg-3 col-12 container-link-gob_img "  href="https://www.gob.pe/sencico/"
      target="_blank" >
        <img src="assets/img/sencico.png" alt="" />
      </a>
    </section>
  </div>

