<div id="notfound">
    <div class="notfound-bg">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="notfound">
        <div class="notfound-404">
            <h1>404</h1>
        </div>
        <h2>Page Not Found</h2>
        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
        <a href="#">Homepage</a>
        <div class="notfound-social">
            <a
          class="link_redes"
          href="https://www.facebook.com/MunicipalidadLambayeque/?fref=ts" target="_blank"
          ><i class="fa-brands fa-facebook"></i
        ></a>
        <a class="link_redes" href="#" 
          ><i class="fa-brands fa-instagram"></i
        ></a>
        <a
          class="link_redes"
          href="https://www.youtube.com/channel/UCWg1Yk1aq2E4H9diBhbyJOw" target="_blank"
          ><i class="fa-brands fa-youtube"> </i
        ></a>
            <!-- <a href="#"><i class="fa fa-twitter"></i></a> -->
            
        </div>
    </div>
</div>