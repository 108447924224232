<div class="container height_container mt-4">
  <h1>Directivas Internas</h1>
  <div class="container_tabla">
<!--     <table datatable class="row-border hover " [dtOptions]="dtOptions"  [dtTrigger]="dtTrigger"  id="example" >  
      <thead>
        <tr class="">
          <th class="uno">nombre</th>
          <th class="dos">tipo</th>
          <th class="tres">descripciòn</th>
          <th class="cuatro">fecha</th>
          <th class="cinco">link</th>
        </tr>
      </thead>
      <tbody *ngIf="data != null" >
        <tr *ngFor="let documento of data">
          <td >{{documento.nombre_doc}}</td>
          <td >{{documento.tipo_doc}}</td>
          <td >
            <span *ngIf="documento.descripcion_doc != null " [innerHTML]='documento.descripcion_doc.split(" ").slice(0,10).join(" ")' ></span>
            <span *ngIf="documento.descripcion_doc == null ">-------</span>
            
          </td>
          
          <td > {{documento.fecha_doc}}   </td>
          <td ><a href="{{documento.link_doc}}">Ver</a></td>
        </tr>

      </tbody>
    </table> -->



    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="announceSortChange($event)"
   >
   <!--  matSort (matSortChange)="announceSortChange($event)" mat-sort-header -->
    <ng-container matColumnDef="nombre_doc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by nombre_doc" >Nombre</th>
      <td mat-cell *matCellDef="let element">
        {{ element.nombre_doc}}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="descripcion_doc">
      <th  class="w-max" mat-header-cell *matHeaderCellDef > Descripcion</th>
      <td  class="w-max" mat-cell *matCellDef="let element" [innerHTML]=" element.descripcion_doc.replaceAll('NÂ°','N°') "></td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="fecha_doc">
      <th class="w-max" mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
      <td class="w-max" mat-cell *matCellDef="let element">{{ element.fecha_doc | slice:0:10 }}</td> 
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="link_doc">
      <th class="w-max" mat-header-cell *matHeaderCellDef  >Ver</th>
      <td class="w-max" mat-cell *matCellDef="let element">
        <a (click)="openPdf(element.link_doc)" role="button" target="_blank" ><img src="assets/img/pdf-ico.png" alt=""></a>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
  </div>
</div>



