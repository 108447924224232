import { Component } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { tap } from 'rxjs';
import { SearchServiceService } from '../servicios/search-service.service';

@Component({
  selector: 'app-page-noticias',
  templateUrl: './page-noticias.component.html',
  styleUrls: ['./page-noticias.component.css']
})
export class PageNoticiasComponent {

  id:Number;
  resultado: any;

  constructor(private router:ActivatedRoute, private searchService:SearchServiceService){
    this.id = 0;
  }

  ngOnInit(){
    this.id =this.router.snapshot.params['id'];
    //console.log("id_pasado: ",this.id);
    this.getObtengoNoticiaCompleta();
  }

  getObtengoNoticiaCompleta():void{
      this.searchService.getObtenerNoticia(this.id)
      .subscribe(
          (data) =>  {this.resultado =data;
          //console.log("resulrtaooo:",this.resultado)
          }
      );
  }

}
