import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';

const apiUrl = `${environment.other.apiUrl}`;
const headers = new HttpHeaders().set('Content-Type', 'application/json');
const options = { headers };


@Injectable({
  providedIn: 'root'
})

export class NoticiaServiceService{

    private urlNoticiasRequestMapping = `${apiUrl}noticias`;
    meses:string [];
    
    constructor(private http : HttpClient) {
     this.meses=["","Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
    
    }

  getNoticias(): Observable<any>{
    // console.log(this.http.get<any>(`${this.urlNoticiasRequestMapping}/listar`)); 
    return this.http.get<any>(`${this.urlNoticiasRequestMapping}/listar`,options);
  } 

  getMeses(){
    return this.meses;
  }

}
