import { Component } from '@angular/core';

@Component({
  selector: 'app-organigramas',
  templateUrl: './organigramas.component.html',
  styleUrls: ['./organigramas.component.css']
})
export class OrganigramasComponent {

}
