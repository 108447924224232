import { Component} from '@angular/core';


@Component({
  selector: 'app-selider-tarjeta',
  templateUrl: './selider-tarjeta.component.html',
  styleUrls: ['./selider-tarjeta.component.css']
})
export class SeliderTarjetaComponent  {
  constructor() { }
  ngOnInit(): void { }
   
  imgCollection: Array<object> = [
      {
        /* image: 'https://loremflickr.com/g/600/400/paris', */
        image: './assets/img/turismo/pimentel.jpg', 
        thumbImage: './assets/img/turismo/pimentel.jpg',
        alt: 'Pimentel',
        title: 'Pimentel '
      }, {
        image: './assets/img/turismo/bosque_pomac.jpg',
        thumbImage: './assets/img/turismo/bosque_pomac.jpg',
        title: 'Museo de Sitio Chotuna Chornancap',
        alt: 'Museo de Sitio Chotuna Chornancap'
      }, {
        image: './assets/img/turismo/kingkong.jpg',
        thumbImage: './assets/img/turismo/kingkong.jpg',
        title: 'Feria del king kong',
        alt: 'Feria del king kong'
      }, {
        image: './assets/img/turismo/museo_tumbas_reales.jpg',
        thumbImage: './assets/img/turismo/museo_tumbas_reales.jpg',
        title: 'Museo Tumbas Reales de Sipan',
        alt: 'Museo Tumbas Reales de Sipan'
      }, {
        image: './assets/img/turismo/arte.jpg',
        thumbImage: './assets/img/turismo/arte.jpg',
        title: 'Arte',
        alt: 'Arte'
      }, {
        image: './assets/img/turismo/casa_monjoy.jpg',
        thumbImage: './assets/img/turismo/casa_monjoy.jpg',
        title: 'Casa Monjoy',
        alt: 'Casa Monjoy',
      }, {
        image: './assets/img/turismo/museo_tumbas_reales.jpg',
        thumbImage: './assets/img/turismo/museo_tumbas_reales.jpg',
        title: 'Museo Tumbas Reales de Sipan',
        alt: 'Museo Tumbas Reales de Sipan'
      }, {
        image: './assets/img/turismo/bruning.jpg',
        thumbImage: './assets/img/turismo/bruning.jpg',
        title: 'Museo Bruning',
        alt: 'Museo Bruning'
      }, {
        image: './assets/img/turismo/tinajones.jpg',
        thumbImage: './assets/img/turismo/tinajones.jpg',
        title: 'Reservorio de Tinajones',
        alt: 'Reservorio de Tinajones',
      }
  ];
  
}
