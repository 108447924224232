import { Component, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { EstadisticasService } from '../servicios/estadisticas.service';

import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { data } from 'jquery';
import { map, tap } from 'rxjs';


@Component({
  selector: 'app-consolidado',
  templateUrl: './consolidado.component.html',
  styleUrls: ['./consolidado.component.css']
})
export class ConsolidadoComponent {
  ELEMENT_DATA: Consolidado[] = [];
  /* -- VARAIBLES DE INPUTS  */
  valueType: String = "";
  //end: String = "";
  anio: string = "";
  //cbxTodo: Boolean = false;
  //cbxAnio: Boolean = false;

  valueTipo: string = "1";

  view: [Number, Number] = [700, 350];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;


  single: any = [];
  /* variables de la otra grafica */
  // options
  /* --------------- */


  colorScheme = {
    domain: [ '#AAAAAA', '#C7B42C','#30BF02','#0627F7','#5AA454','#A10A28', '#C7B42C',]
  };

  constructor(private estidisticaService: EstadisticasService) { }


  filtrar(){
    this.getFiltrarAllTipo(parseInt( this.anio));
  }

  getFiltrarAllTipo(anio:number ) {
    //usamos promesas para evitar que la grafica se renderize antes de llenar los datoss
    //este metodo recibe e lso datos pertenecientes a un solo año, pero de los 4 tipos
    this.estidisticaService.getFiltrarTodo(anio).pipe(
      map(data => {
        //console.log("data aa", data)
        this.ELEMENT_DATA = data;
        //console.log(this.ELEMENT_DATA)
        return this.recorrerJsonAConsolidadoTipo();
      })
    ).subscribe(single => {
      this.single = single;
      //console.log("all tipo", this.single)
    });
  }
  recorrerJsonAConsolidadoTipo() {
    this.single = [];
    const single = this.ELEMENT_DATA.map(item => {
      return {
        name: item.tipo.toString(),
        value: item.cantidad
      };
    });
    return single;
  }

  onSelect(data: any): void {
    /*  console.log("dataselecr", data) */
    JSON.parse(JSON.stringify(data))
    //console.log('Item clicked', );
  }

  onActivate(data: any): void {
    /*  console.log("datasele2", data) */
    JSON.parse(JSON.stringify(data))
    //console.log('Activate', );
  }

  onDeactivate(data: any): void {
    JSON.parse(JSON.stringify(data));
    //console.log('Deactivate', );
  }


}

export interface Consolidado {

  idconsolidado: Number;
  tipo: Number;
  subtipo: Number;  //'según: ordinaria, extraordinaria, parte policial, judicial, masiva, etc.',
  mes: Number;
  anio: Number;
  sexo: Number;
  cantidad: number;

}


/* getConsolidado() {
  this.estidisticaService.getConsolidado().subscribe
    (
      (data) => {
        this.ELEMENT_DATA = data
        this.dataSource = new MatTableDataSource<Consolidado>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
}
*/
/*  announceSortChange(sortState: Sort) {

   if (sortState.direction) {
     this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
   } else {
     this._liveAnnouncer.announce('Sorting cleared');
   }
 }
*/
/* ------------  */

/* Variables de para TABLA DE ANGNLAR MATERIAL */
/* @ViewChild(DataTableDirective, { static: false })
dtOptions: DataTables.Settings = {};

ELEMENT_DATA: Consolidado[] = [];
@ViewChild(MatPaginator) paginator!: MatPaginator;
@ViewChild(MatSort) sort!: MatSort; */

/* Preparamos la tabla  - columns*/
  //displayedColumns: string[] = ['periodo', 'sexo', 'tipo', 'subtipo', 'cantidad'];

  //creamo el elemento datasource
  //dataSource = new MatTableDataSource<Consolidado>(this.ELEMENT_DATA);

/* --------------------- END VARIABLES ------------- */