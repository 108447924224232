
  
    <main>
      <div class="container pt-4 mb-5 height_container ">
        <h1>> Oficinas</h1>
        <div class="mt-5">
          <table datatable class="row-border hover  " [dtOptions]="dtOptions" 
           [dtTrigger]="dtTrigger"  id="example" >  
            <thead class="bgcolor" >
              <tr >
                <th class="uno">Nombre</th>
                <th class="dos">Observacion</th>
                <th class="tres">Telefono</th>
                <th class="cuatro">Correo</th>
                <!-- <th class="cinco">link</th> -->
              </tr>
            </thead>
            <tbody *ngIf="data != null" >
              <tr *ngFor="let documento of data">
                <td >{{documento.nombre.replaceAll('Ã','Á').replaceAll('Ã‰', 'É')
                  .replaceAll("Ã","Í").replaceAll("NÂº ","Nº").replaceAll('Ã“', 'Ó')
                  .replaceAll("Â°","º").replaceAll('Ã�','Á').replaceAll("Ãš","Ú").replaceAll("Ã‘","Ñ")}}</td>
                <td [innerHTML]="documento.observacion"></td>
                <td> {{documento.telefono.replaceAll('Ã','Á').replaceAll('Ã‰', 'É')
                  .replaceAll("Ã","Í").replaceAll("NÂº ","Nº")
                  .replaceAll("Â°","º").replaceAll('Ã“', 'Ó').replaceAll("Ãš","Ú")}}</td>
                <td > {{documento.correo}}</td>
               <!--  <td ><a href="{{documento.url}}">Ver</a></td> -->
              </tr>
      
            </tbody>
          </table>
        </div>
       
      </div>
    </main>

