<!-- PARA REALIZAR TRAMITES -->
<!-- <div *ngIf="band == 'nuevo-tramite'">
  <iframe
    src="https://www.munilambayeque.gob.pe/intranet/tramite/"
    width="100%"
    frameborder="0"
  >
  </iframe>
</div> -->

<!-- <div *ngIf="band==2">
    <iframe src="https://reclamos.servicios.gob.pe/?institution_id=284" width="100%" frameborder="0">
    </iframe>
</div> -->

<!-- PARA PORTAL DE TRANSPARENCIA -->
<div *ngIf="band == 'portal-de-transparencia'">
  <iframe
    src="https://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=11428#.ZCrNW3tBzIW"
    frameborder="0"
  >
  </iframe>
</div>

<!-- PARA INTRANET - BUSCAR O CONSULTAR TRAMITES -->
<div *ngIf="band == 'consultar-tramite'">
  <iframe
    src="https://www.munilambayeque.gob.pe/INTRANETRAM/index1.php"
    frameborder="0"
  >
  </iframe>
</div>

<!-- IFRAME - ACCESO A LA INFORMACION PÚBLICA -->
<div *ngIf="band == 'informacion-publica'">
  <iframe
    src="https://www.gob.pe/20399-solicitar-acceso-a-la-informacion-publica?child=15479"
    frameborder="0"
  ></iframe>
</div>

<!-- TRAMITES WEB GOB.PE -->
<div *ngIf="band == 'tramites-y-servicios'">
  <div class="container_qr">
    <img src="assets/img/logo_libroreclamacionesqr.jpg" alt="" />
  </div>
  <iframe
    src="https://www.gob.pe/institucion/munilambayeque/tramites-y-servicios"
    frameborder="0"
  ></iframe>
</div>

<!-- BOLETINES -->
<div *ngIf="band == 'boletines'">
  <iframe
    src="https://www.gob.pe/institucion/munilambayeque/informes-publicaciones/tipos/2-boletin"
    frameborder="0"
  ></iframe>
</div>

<!-- INFORMES Y PUBLICACIONES -->
<div *ngIf="band == 'informes-publicaciones'">
  <iframe
    src="https://www.gob.pe/institucion/munilambayeque/informes-publicaciones"
    frameborder="0"
  ></iframe>
</div>

<!-- NORMAS LEGALES -->
<div *ngIf="band == 'normas-legales'">
  <iframe
    src="https://www.gob.pe/institucion/munilambayeque/normas-legales"
    frameborder="0"
  ></iframe>
</div>

<!-- agenda-del-alcalde -->

<!-- TUPA -->

<div *ngIf="band == 'TUPA'">
  <iframe
    src="https://www.gob.pe/institucion/munilambayeque/informes-publicaciones/3135036"
    frameborder="0"
  ></iframe>
</div>

<div *ngIf="band == 'intranet'">
  <iframe
    src="
    https://www.munilambayeque.gob.pe/intranet/login.php"
    frameborder="0"
  ></iframe>
</div>

<div *ngIf="band == 'municipalidadlicencias'">
  <div class="container w-75">
    <h1 class="my-4 ">Licencias</h1>
    <article class="mb-5">
      <b>REQUISITOS PARA TRAMITAR UNA LICENCIA DE FUNCIONAMIENTO</b>
      <br />
      Licencia de Funcionamiento para: establecimientos con un área hasta 100m2
      y con capacidad no mayor de almacenamiento de 30% del área total del local
      con ITSE BÁSICA EX POST <a href="https://cdn.www.gob.pe/uploads/document/file/5010577/1.%20ITSEBASICAEXPOST.pdf?v=1692366405" target="_blank">Descargar </a> <br>  Licencia
      de Funcionamiento para establecimientos con un área de más de 100 m2 hasta
      500 m2 con ITSE BÁSICA EX ANTE
      <a href="https://cdn.www.gob.pe/uploads/document/file/5010587/2.%20ITSEBASICAEXANTE.pdf?v=1692366558" target="_blank">Descargar </a> <br>  Licencia de Funcionamiento para:
      establecimientos con un área de más de 500 m2 y no comprendidos en las
      categorías anteriores que apliquen para una ITSE DE DETALLE o
      MULTIDISCIPLINARIA <a href="https://cdn.www.gob.pe/uploads/document/file/5010606/3.%20MULTIDISCIPLINARIA.pdf?v=1692366721" target="_blank">Descargar </a> <br>
      <br />
      <b>FORMULARIOS</b>
      <br />
      Declaración Jurada solicitando Licencia de Funcionamiento
      <a href="https://cdn.www.gob.pe/uploads/document/file/5010639/4.%20FormatoGratuitoDeclaracionLF.pdf?v=1692366959" target="_blank">Descargar </a> <br> Declaración Jurada Condiciones de Seguridad
      <a href="https://cdn.www.gob.pe/uploads/document/file/5010650/5.%20DeclaracionJuradaObservancia.pdf?v=1692367094" target="_blank">Descargar </a> <br> Modelo de Llenado de Formularios Ejemplo 1
      <a href="https://cdn.www.gob.pe/uploads/document/file/5010664/6.%20EjemploFormatoGratuitoDeclaracionLF.pdf?v=1692367232" target="_blank">Descargar </a> <br> 
      Ejemplo 2 <a href="https://cdn.www.gob.pe/uploads/document/file/5010674/7.%20EjemploDeclaracionJuradaObservancia.pdf?v=1692367355" target="_blank">Descargar </a> <br>
      <br />


      NORMAS LEGALES SOBRE LICENCIAS DE FUNCIONAMIENTO
      <br />
      Ley N° 28976 Ley Marco de Licencia de Funcionamiento
      <a href="https://cdn.www.gob.pe/uploads/document/file/5010853/8.%20Ley28976.pdf?v=1692368796" target="_blank">Descargar </a> <br> Decreto Legislativo N° 1200 Modifica los
      Artículos 2,3,6,7,8,9,11,13 y 15 de la Ley N° 28976
      <a href="https://cdn.www.gob.pe/uploads/document/file/5010914/9.%20DecretoLegislativo1200.pdf?v=1692369212" target="_blank">Descargar </a> <br> Decreto Supremo N° 006-2013-PCM Aprueban
      Relación de Autorizaciones Sectoriales de Entidades del Poder Ejecutivo
      que deben ser exigidas como requisito previo para el otorgamiento de
      Licencias de Funcionamiento <a href="https://cdn.www.gob.pe/uploads/document/file/5011176/10.%20DSupremo0062013.pdf?v=1692371155" target="_blank">Descargar </a> <br> Decreto Supremo N°
      058-2014-PCM Aprueba el Reglamento de Inspecciones Técnicas de Seguridad
      en Edificaciones <a href="https://cdn.www.gob.pe/uploads/document/file/5011282/11.%20DSupremo0582014PCM.pdf?v=1692372149" target="_blank">Descargar </a> <br> Ordenanza Municipal N°
      011/2016-MPL Modifica en parte el TUPA adecuándolo a los Procedimientos
      Administrativos de Licencias de Funcionamiento e Inspecciones Técnicas de
      Seguridad en Edificaciones <a href="https://cdn.www.gob.pe/uploads/document/file/2358880/ordenanza%20municipal.pdf" target="_blank">Descargar </a> <br>
      <br />
      <br />
      <b>NORMAS COMPLEMENTARIAS</b>
      <br />
      Decreto Supremo N° 096-2007 Regula la Fiscalización Posterior Aleatoria de
      los Procedimientos Administrativos por parte del Estado
      <a href="https://cdn.www.gob.pe/uploads/document/file/5011781/13.%20DSupremo0962007.pdf?v=1692373071" target="_blank">Descargar </a> <br> Ordenanza Municipal N° 031-2008 Regula la
      Comercialización y consumo de Bebidas Alcohólicas en el Distrito de
      Lambayeque <a href="https://cdn.www.gob.pe/uploads/document/file/2358988/ordenanza%20municipal.pdf" target="_blank">Descargar </a> <br> Ordenanza Municipal N° 025-2015
      Reglamento de Aplicación de Sanciones y el Cuadro de Infracciones y
      Sanciones de La Municipalidad Provincial de Lambayeque
      <a href="https://cdn.www.gob.pe/uploads/document/file/2358868/ordenanza%20municipal.pdf" target="_blank">Descargar </a> <br> Ley N° 29060 Ley de Silencio Administrativo
      <a href=" https://cdn.www.gob.pe/uploads/document/file/5011822/16.%20Ley29060.pdf?v=1692373377" target="_blank">Descargar </a> <br> Ley N° 27444 Ley de Procedimiento Administrativo
      General <a href="https://cdn.www.gob.pe/uploads/document/file/5011830/17.%20Ley27444.pdf?v=1692373469" target="_blank">Descargar </a> <br> Ordenanza Municipal N° 0212014
      Simplifica el Procedimiento de Licencia de Funcionamiento y Vinculados en
      el distrito de Lambayeque <a href="https://cdn.www.gob.pe/uploads/document/file/2358839/ordenanza%20municipal.pdf" target="_blank">Descargar </a> <br> Ley N° 28296 Ley
      General del Patrimonio Cultural de la Nación <a href="https://cdn.www.gob.pe/uploads/document/file/5011870/19.%20Ley28296.pdf?v=1692373648" target="_blank">Descargar </a> <br>
      <br />
      <br />
      <b>PLANO DE ZONIFICACIÓN</b>
      <br />
      <br />
      Normatividad del Plano de Zonificación Ordenanza Municipal Nº 003/2016-MPL
      <a href="https://cdn.www.gob.pe/uploads/document/file/2358865/ordenanza%20municipal.pdf" target="_blank">Descargar </a> <br>

      <br />
      Oficio Nº 2787-2015-VIVIENDA-VMVU-DGPRVU <a href="https://cdn.www.gob.pe/uploads/document/file/5011888/21.%20Oficio27872015.pdf?v=1692373824" target="_blank">Descargar </a> <br>
      <br />
      Reglamento de ordenamiento ambiental urbano de la ciudad de Lambayeque
      <a href="https://cdn.www.gob.pe/uploads/document/file/5011936/22.%20Reglamento.pdf?v=1692374234" target="_blank">Descargar </a> <br>
      <br />
      Plano de Zonificación Plano de Zonificación en PDF
      <a href="https://cdn.www.gob.pe/uploads/document/file/5011975/23.%20PlanoZonificacionMod.pdf?v=1692374499" target="_blank">Descargar </a> <br>
      <br />
      Índices de Uso para la Ubicación de Actividades Urbanas Listado de
      Actividades Urbanas y su compatibilidad de usos 
      <a href="https://cdn.www.gob.pe/uploads/document/file/5011993/24.%20ListadoActiviUrbanascomp.pdf?v=1692374605" target="_blank">Descargar </a> <br>
      <br />
      Parámetros normados para zonificación residencial y comercial
      <a href="https://cdn.www.gob.pe/uploads/document/file/5012011/25.%20Parametrosnorzonireycome.pdf?v=1692374750" target="_blank">Descargar </a> <br>

      <br />
      <b>ESTADO DE SU TRÁMITE</b>
      <br />
      Cuadro 2: Estado de las solicitudes de Licencias de Funcionamiento
      (Período de enero de 2014 al 16 de diciembre 2016)
      <a href="https://cdn.www.gob.pe/uploads/document/file/5012011/25.%20Parametrosnorzonireycome.pdf?v=1692374750" target="_blank">Descargar </a> <br>
      <br />
      <br />
      <br />
      <b>CENTROS DE ATENCIÓN</b>
      <br />
      Av. Bolívar N° 400 - Oficina TramiFacil <br> 
      Calle Ferreñafe N° 600 P. J. San Martin Sub Gerencia de Desarrollo Económico
    </article>
  </div>
</div>

<div *ngIf="band == 'tributacion'">
  <div class="container my-lg-5 my-3">
    <h3 class="my-lg-3 my-3">FORMULARIOS</h3>
      <ul> > Formulario para la Declaración Jurada del Impuesto Predial
        <li><a target="_blank" href="https://cdn.www.gob.pe/uploads/document/file/5012388/1.%20formato_hr.pdf?v=1692377484">Formulario HR</a></li>
        <li><a target="_blank" href="https://cdn.www.gob.pe/uploads/document/file/5012396/2.%20formato_pu.pdf?v=1692377590">Formulario PU</a></li>
        <li><a target="_blank" href="https://cdn.www.gob.pe/uploads/document/file/5012423/3.%20ddjj_actualiz_domfiscal.pdf?v=1692377726">Declaración Jurada de Actualización de Domicilio Fiscal</a></li>
        <li><a target="_blank" href="https://cdn.www.gob.pe/uploads/document/file/5012438/4.%20indicaciones_para_llenado_formularios_hr_pu_1_.pdf?v=1692377845">Indicación para el llenado de los Formularios</a></li>
      </ul>
      <ul> > Ejemplos de llenado de los Formularios
        <li><a target="_blank" href="https://cdn.www.gob.pe/uploads/document/file/5012449/5.%20ejemplo_llenado_formato_hr.pdf?v=1692377946">Ejemplo de llenado de Formulario HR</a></li>
        <li><a target="_blank" href="https://cdn.www.gob.pe/uploads/document/file/5012456/6.%20ejemplo_llenado_formato_pu.pdf?v=1692378014">Ejemplo de llenado de Formulario PU</a></li>
      </ul>

    <h3 class="my-lg-3 my-3">LEGISLACIÓN</h3>
      <ul>
        <li><a target="_blank" href="https://cdn.www.gob.pe/uploads/document/file/5012472/8.%20Ley_de_Tributaci%C3%B3n_Municipal.pdf?v=1692378144">Ley de tributacion Municipal</a></li>
      </ul>
  </div>
</div>