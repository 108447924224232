<style>
  h1 {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    color: #003c7b;
    display: block;
  }
  select {
    font-size: 16px;
    border-radius: 5px;
    border: 2px solid #ccc;
    padding: 3px 10px;
    width: 100%;
    box-sizing: border-box;
    color: black;
    /* -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; */
  }

  select:focus {
    border-color: #007bff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  select option {
    background-color: white;
    color: black;
  }
  .width-lg-300px {
    width: 300px;
  }
  .width-lg-600px{
    width: 600px;
  }
</style>
<div class="container mb-5 ">
  <div class="d-flex align-items-center  justify-content-between mt-5">
    <!-- <div class=">.col-md-4</div> -->
    <!-- <div class="col-md-4 offset-md-4">.col-md-4 .offset-md-4</div> -->
    <h1 class="col-sm-5 col-md-6">Obras - Titulo dinamico</h1>
    <select class="col-sm-5 width-lg-300px" aria-label="Default select example">
      <option value="1">En ejecucion</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </select>
  </div>

  <table
    id="example"
    class="table table-striped table-bordered mt-3"
    style="width: 100%"
  >
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Codigo SNIP</th>
        <th>Descripcion</th>
        <th>Fecha</th>
        <th>Fecha</th>
        <th><a href="">Ver </a></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Tiger Nixon</td>
        <td>184471</td>
        <td class="width-lg-600px">
          Lorem ipsum Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
          neque, magnam, dignissimos harum similique facilis fugiat ipsam nisi
          culpa assumenda, qui quia minus maiores corrupti asperiores.
          Voluptatum porro nemo excepturi? Lorem ipsum Lorem, ipsum dolor sit
          amet consectetur adipisicing elit. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Suscipit neque, magnam, dignissimos
          harum similique facilis fugiat ipsam nisi culpa assumenda, qui quia
          minus maiores corrupti asperiores. Voluptatum porro nemo excepturi?
        </td>
        <td>2011-04-25</td>
        <td>2011-04-25</td>
        <td><a href="">Ver completo</a></td>
      </tr>
      <tr>
        <td>Tiger Nixon</td>
        <td>184471</td>
        <td class="width-lg-600px">
          Lorem ipsum Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
          neque, magnam, dignissimos harum similique facilis fugiat ipsam nisi
          culpa assumenda, qui quia minus maiores corrupti asperiores.
          Voluptatum porro nemo excepturi? Lorem ipsum Lorem, ipsum dolor sit
          amet consectetur adipisicing elit. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Suscipit neque, magnam, dignissimos
          harum similique facilis fugiat ipsam nisi culpa assumenda, qui quia
          minus maiores corrupti asperiores. Voluptatum porro nemo excepturi?
        </td>
        <td>2011-04-25</td>
        <td>2011-04-25</td>
        <td><a href="">Ver completo</a></td>
      </tr>
    </tbody>
  </table>
</div>
