

<div class="container_2 h-125">
  <div class="row justify-content-center align-items-center">
    <div class="col-4 col-sm-3 col-md-3 col-lg-3 d-flex">
      <span class="btn_menu" (click)="toggleMenuClass()" role="button"
        ><input type="button" id="btn_menu" class="btn_menu form form-control" /><i
          class="fas fa-solid fa-bars"
        ></i
      ></span>
      <a class="container_img_escudo" href="">
        <img
          class="img_escudo pb-2"
          src="assets/img/LOGO_MUNI.png"
          alt="imagen escudo"
        />
        <!-- src="assets/img/escudolamba.png" -->
        <h1>Municipalidad Provincial de Lambayeque</h1>
      </a>
    </div>

    <div
      class="col-8 col-sm-9 col-md-8 col-lg-8 d-flex justify-content-around align-items-center"
    >
      <form class="from_nav_search ms-1">
        <input
          class="input_search"
          type="text"
          id="valorBusquedad"
          name="valorBusquedad"
          [(ngModel)]="valorBusquedad"
          placeholder="  Buscar"
        />
        <i
          type="button"
          class="fa-solid fa-magnifying-glass ms-1 me-3"
          (click)="getBusquedad()"
        ></i>
        <!-- routerLink="/page-search/lambayeque" -->

        <!-- [(ngModel)]="value_input_search"   #newItem-->
        <!-- (click)="addNewItem(newItem.value)"  -->
      </form>

      <a class="img_transparencia ms-3 uno" routerLink="/gobierno/portal-de-transparencia">
        <img
          src="assets/img/Transparencia.png"
          alt="IMG PORTAL DE TRANSPARENCIA"
        />
      </a>
      <!-- https://www.munilambayeque.gob.pe/intranet/tramite/ -->
      <a class="img_transparencia ms-3 dos"    href="https://intranet.munilambayeque.gob.pe/intranet/mesadepartes" target="_blank">
        <img src="assets/img/tramite.png" alt="IMG TRAMITE DOCUMENTARIO" />
      </a>
      <a
        class="img_transparencia ms-3 tres"
        href="https://reclamos.servicios.gob.pe/?institution_id=284"
        target="_blank"
      >
        <img
          src="assets/img/libro-de-reclamaciones-LOGO.png"
          alt="IMG LIBRO DE RECLAMACIONES"
        />
      </a>

      <!--  <img class="ms-1 img_logo_PERU" src="assets/img/logos-peru.jpg" alt="Logo" /> -->
    </div>
  </div>
</div>

<div class="container_2 header_nav_mobil">
  <div class="d-flex justify-content-center">
    <a class="img_transparencia ms-3" routerLink="/gobierno/portal-de-transparencia">
      <img
        src="assets/img/Transparencia.png"
        alt="IMG PORTAL DE TRANSPARENCIA"
      />
    </a>
    <!-- https://www.munilambayeque.gob.pe/intranet/tramite/ -->
    <a class="img_transparencia ms-3" href="https://www.munilambayeque.gob.pe/mesadepartes" target="_blank">
      <img src="assets/img/tramite.png" alt="IMG TRAMITE DOCUMENTARIO" />
    </a>
    <a
      class="img_transparencia ms-3"
      href="https://reclamos.servicios.gob.pe/?institution_id=284" target="_blank"
    >
      <img
        src="assets/img/libro-de-reclamaciones-LOGO.png"
        alt="IMG LIBRO DE RECLAMACIONES"

      />
    </a>
  </div>
</div>

<!-- NAV -->


  <div
  class="bg_nav contanier-nav w-100 p-3"
  [ngClass]="btn_menu == 1 ? 'activate' : 'normal'"
>
  <!-- [@nombreAnimacion]="state"-->
  <nav class="container">
    <ul class="d-flex contanier-nav-ul justify-content-center">
      <li class="dropdown">
        <a routerLink="" (click)="toggleMenuClass()">
          <span class="icon_cerrsubitem"> > </span> INICIO<b class="caret"></b>
        </a>
      </li>
      <li class="dropdown">
        <a role="button">
          <span class="icon_cerrsubitem"> > </span>MUNICIPALIDAD
          <b class="caret"></b>
        </a>
        <ul class="dropdown-menu">
          <li class="inicio">
            <a routerLink="/presentacion" (click)="toggleMenuClass()"
              >PRESENTACION</a
            >
          </li>

          <li class="inicio">
            <a routerLink="/organigramas" (click)="toggleMenuClass()"
              >ORGANIGRAMA</a
            >
          </li>
          <li class="inicio">
            <a href="https://www.gob.pe/institucion/munilambayeque/funcionarios" target="_blank" (click)="toggleMenuClass()"
              >AUTORIDADES</a
            >
          </li>
          <li class="inicio">
            <a href="https://www.gob.pe/institucion/munilambayeque/sedes" target="_blank" (click)="toggleMenuClass()">OFICINAS</a>
          </li>
          
          <li class="inicio">
            <a routerLink="/gobierno/municipalidadlicencias" (click)="toggleMenuClass()"
              >LICENCIA DE FUNCIONAMIENTO</a
            >
          </li>
          <li class="inicio">
            <a routerLink="/gobierno/tributacion" (click)="toggleMenuClass()"
              >TRIBUTACION</a
            >
          </li>

        </ul>
      </li>

      <li class="dropdown">
        <a role="button">
          <span class="icon_cerrsubitem"> > </span>TRANSPARENCIA<b
            class="caret"
          ></b>
        </a>
        <ul class="dropdown-menu">
          <li class="inicio">
            <a
              href="https://www.transparencia.gob.pe/reportes_directos/pte_transparencia_info_finan.aspx?id_entidad=11428&id_tema=19&ver=" target="_blank"
              (click)="toggleMenuClass()"
              >TRANSPARENCIA ECONÓMICA</a
            >
          </li>
          <li class="inicio">
            <a href="https://www.gob.pe/institucion/munilambayeque/normas-legales" target="_blank" (click)="toggleMenuClass()"
              >NORMAS LEGALES</a
            >
          </li>
          <!-- <li class="inicio">
            <a routerLink="/directivas" (click)="toggleMenuClass()"
              >DIRECTIVAS</a
            >
          </li> -->
          <li class="inicio">
            <a
              href="https://www.transparencia.gob.pe/personal/pte_transparencia_personal.aspx?id_entidad=11428&id_tema=32&ver="
             target="_blank"
              (click)="toggleMenuClass()"
              >INFORMACIÓN SOBRE EL PERSONAL</a
            >
          </li>
          <li class="inicio">
            <a href="https://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=11428#.ZCrNW3tBzIW" (click)="toggleMenuClass()" target="_blank"
              >PORTAL DE TRANSPARENCIA</a
            >
          </li>
          <!-- <li class="inicio">
            <a routerLink="/estadisticas/consolidados" (click)="toggleMenuClass()"
              >ESTADÍSTICAS</a
            >
          </li> -->
        </ul>
      </li>
      <li class="dropdown">
        <a role="button">
          <span class="icon_cerrsubitem"> > </span>TRAMITES<b class="caret"></b>
        </a>
        <ul class="dropdown-menu">
          <!-- <li class="inicio">
            <a href="https://www.munilambayeque.gob.pe/intranet/login.php" target="_blank" (click)="toggleMenuClass()"
              >INTRANET</a
            >
          </li> -->
        <!--   <li class="inicio">
            <a href="http://www.munilambayeque.gob.pe/mesadepartes/" target="_blank" (click)="toggleMenuClass()">MESA DE PARTES</a>
          </li> -->
          <li class="inicio">
            <a href="https://intranet.munilambayeque.gob.pe/intranet/" target="_blank" (click)="toggleMenuClass()">INTRANET TRAMITE</a>
          </li>
          <!-- <li class="inicio">
            <a href="https://www.sistemaprivado.com/mesadepartes" target="_blank" (click)="toggleMenuClass()">MESA DE PARTES</a>
          </li> -->
          <li class="inicio">
            <a href="https://intranet.munilambayeque.gob.pe/intranet/mesadepartes" target="_blank" (click)="toggleMenuClass()">MESA DE PARTES</a>
          </li>
          <li class="inicio">
            <a href="https://intranet.munilambayeque.gob.pe/intranet/consultatutramite" target="_blank" (click)="toggleMenuClass()"
              >SEGUIMIENTO DE TU TRAMITE</a
            >
          </li>
          <li class="inicio">
            <a routerLink="/gobierno/tramites-y-servicios" (click)="toggleMenuClass()" >TODOS LOS TRAMITES</a>
          </li>
        </ul>
      </li>
      <li class="dropdown">
        <a role="button">
          <span class="icon_cerrsubitem"> > </span> SERVICIOS<b
            class="caret"
          ></b>
        </a>
        <ul class="dropdown-menu">
          <!-- <li class="inicio">
            <a routerLink="/seguridad" (click)="toggleMenuClass()"
              >SEGURIDAD
            </a>
          </li>
          <li class="inicio">
            <a
              role="button"
              (click)="toggleMenuClass()"
              (click)="irServicios(6)"
              >MAS SERVICIOS</a
            >
          </li> -->
          <li class="inicio">
            <a routerLink="/page-turismo" (click)="toggleMenuClass()"
              >TURISMO</a
            >
          </li>
          </ul  >
        
        
        <!-- <ul class="dropdown-menu">
          <li class="inicio">
            <a
              role="button"
              (click)="toggleMenuClass()"
              (click)="irServicios(1)"
              >ALUMBRADO PÚBLICO</a
            >
          </li>
          <li class="inicio">
            <a role="button" (click)="irServicios(2)"  (click)="toggleMenuClass()">ESTADIO</a>
          </li>
          <li class="inicio">
            <a routerLink="/page-servicios/3" (click)="toggleMenuClass()"
              >PISCINA MUNICIPAL</a
            >
          </li>
          <li class="inicio">
            <a routerLink="/page-servicios/4" (click)="toggleMenuClass()"
              >GESTIÓN INTEGRAL DE RESIDUOS SÓLIDOS</a
            >
          </li>
          <li class="inicio">
            <a routerLink="/page-servicios/5" (click)="toggleMenuClass()"
              >MANEJO E IMPLEMENTACIÓN DE ÁREAS VERDES URBANAS</a
            >
          </li>
          <li class="inicio">
            <a routerLink="/page-servicios/6" (click)="toggleMenuClass()"
              >BIBLIOTECA MUNICIPAL</a
            >
          </li>
        </ul> -->
      </li>

      <li class="dropdown">
        <a role="button">
          <span class="icon_cerrsubitem"> > </span>PÚBLICO<b class="caret"></b>
        </a>
        <ul class="dropdown-menu">
         
            
          
          <li class="inicio">
            <a
              href="https://www.gob.pe/institucion/munilambayeque/colecciones/22030-convocatorias-mpl"
              target="_blank"
              >CONVOCATORIAS</a
            >
          </li>
          <li class="inicio">
            <a href="https://www.gob.pe/institucion/munilambayeque/noticias" target="_blank" (click)="toggleMenuClass()"> NOTICIAS</a>
          </li>
          <li class="inicio">
            <a
              routerLink="/campanias"
              (click)="toggleMenuClass()"
              >CAMPAÑAS</a
            >
          </li>
          <li class="inicio">
            <a
              routerLink="/gobierno/informes-publicaciones"
              (click)="toggleMenuClass()"
              >INFORMES Y PUBLICACIONES</a
            >
          </li>
          <li class="inicio">
            <a
              href="https://www.gob.pe/institucion/munilambayeque/colecciones/6057-sistema-de-control-interno"
              target="_blank"
              (click)="toggleMenuClass()"
              >CONTROL INTERNO</a
            >
          </li>
          <li class="inicio">
            <a
              routerLink="/gobierno/informacion-publica"
              (click)="toggleMenuClass()"  
              >ACCESO A LA INFORMACION PÚBLICA</a>
          </li>
        </ul>
      </li>
      <li class="dropdown">
        <a role="button">
          <span class="icon_cerrsubitem"> > </span> OTROS<b class="caret"></b>
        </a>
        <ul class="dropdown-menu">
          <!-- <li class="inicio">
            <a
              href="/gobierno/agenda-del-alcalde"
              (click)="toggleMenuClass()"
              >AGENDA DEL ALCALDE</a
            >
          </li> -->
          <!-- <li class="inicio">
            <a
              href="<?php echo HOSTNAME.'enlaces' ?>"
              (click)="toggleMenuClass()"
              >ENLACES</a
            >
          </li> -->
          <li class="inicio">
            <a
              href="https://www.facebook.com/MunicipalidadLambayeque/photos"
              target="_blank"
              (click)="toggleMenuClass()"
              >GALERÍA DE FOTOS</a
            >
          </li>
          <li class="inicio">
            <a
              target="blank"
              href="http://webmail.munilambayeque.gob.pe/"
              (click)="toggleMenuClass()"
              >CORREO INTERNO</a
            >
          </li>
          <li class="inicio">
            <a
              href="https://www.gob.pe/institucion/munilambayeque/funcionarios"
              target="_blank"
              >DIRECTORIO WEB</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>

