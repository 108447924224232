<main class="container pt-5 pb-5"> 
   
    <div class="row g-5">
      <div class="col-md-8">
        <div class="navegacion mb-5">
          <p><a routerLink="/home">Home</a> > Buscar  </p>
        </div>
        <h3 class="pb-4 mb-4 fst-italic border-bottom">
          Noticias relacionadas: <b>{{valorBusquedad}}</b>
        </h3>
        <div *ngFor="let busquedad of resultados.slice(startIndex, endIndex)">
          <article class="blog-post">
            <h5 class="blog-post-title mb-1" (click)="mostrarNoticia(busquedad.idnoticia)">
              {{busquedad.titulo.replaceAll('Ã','Á')
              .replaceAll('Ã‰', 'É')
              .replaceAll("Ã","Í")
              .replaceAll('Ã“', 'Ó') 
              .replaceAll("Ãš","Ú")
              .replaceAll('Ã‘','Ñ')
              .replaceAll("NÂº ","Nº")
              .replaceAll("Â°","º")
              .replaceAll("Ã³","ó")
              .replaceAll("&deg;","º")
              }}
            </h5>
            <p class="blog-post-meta">            
              <span>{{ listaMeses[busquedad.mes] }} {{ busquedad.dia }},{{ busquedad.anio }}.</span>
            </p>
            
            <p [innerHTML]='busquedad.brevedescripcion.split(" ").slice(0, 35).join(" ")+ "..."'>
              
            </p>
          </article>
          <hr/>
        </div> 
        <div class="d-flex justify-content-between">
          <a type="button" (click)="mostratMasNoticias()" class="">Ver más</a>
          <a type="button" (click)="regresar()" class=""> <-Regresar </a>
        </div>
      </div>
  
      <!-- Barra Lateral -->
      <div class="col-md-4">
        <div class="position-sticky" style="top: 2rem">
          <div class="p-4 mb-3 bg-light rounded">
            <div class="mb-0">
              <app-slider3></app-slider3>
            </div>
          </div>
  
          <div class="p-4">
            <h4 class="fst-italic">Lo más visto</h4>
            <ol class="list-unstyled mb-0">
              
              
              <li><a href="https://www.gob.pe/23228-solicitar-certificado-domiciliario?child=15399">> Solicitar certificado domiciliario a la Municipalidad Provincial de Lambayeque</a></li>
              <li><a href="https://www.gob.pe/institucion/munilambayeque/pages/15404-obtener-certificado-de-salubridad-para-locales-comerciales-en-lambayeque">> Obtener certificado de salubridad para locales comerciales. </a></li>
              <li><a href="https://www.gob.pe/20414-obtener-copia-certificada-de-una-partida-o-acta-de-nacimiento-matrimonio-o-defuncion?child=16363">> Solicitar copia certificada de partida de nacimiento, matrimonio o defunción </a></li>
              <li><a href="https://www.gob.pe/20399-solicitar-acceso-a-la-informacion-publica?child=15479">> Solicitar información pública a la Municipalidad Provincial de Lambayeque</a></li>
              <li><a href="https://reclamos.servicios.gob.pe/?institution_id=284" target="_blank">> Libro de reclamaciones</a></li>
              <li><a routerLink="/gobierno/TUPA">> TUPA</a></li>
              <li><a routerLink="/gobierno/informacion-publica">> Acceso a la información pública</a></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
     <!-- <div class="album py-5 bg-light">
      <div class="container">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3  " >
          <div class="col" *ngFor="let busquedad of listaNoticiasBusqueda.slice(0,10)">
            <div class="card shadow-sm">
               <svg class="bd-placeholder-img card-img-top" width="100%" height="225" 
              xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" 
              preserveAspectRatio="xMidYMid slice" focusable="false"><title>busquedad</title><rect width="100%" height="100%" fill="#55500c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">{{busquedad.titulo}}</text>
              </svg> 
              
              <div class="container_img">
                <img src="assets/img/aita.png" alt="">
              </div>  
              
              <div class="card-body">
                <h6 class="card-text">{{busquedad.titulo}}</h6>
                <div class="">
                    <p class="">
                      {{busquedad.descripcion.split(' ').slice(0,10).join(' ')}}
                    </p>
                  <div class=" d-flex justify-content-between align-items-center">
                    <a href="" class="btn  btn-outline-secondary "> Leer</a>
                    <small class="text-muted"> 7 min leactura</small>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div> -->
  </main>