


<!-- ########################################333 -->
<div class="container mt-5">
  <mat-tab-group dynamicHeight >
    <mat-tab label="Convocatorias CAS">
      <div class="example-small-box mat-elevation-z4 defunciones_tabla ">
          
        <div class="container h-250px">
          <h1>CONVOCATORIA DE PERSONAL CAS</h1>
          <form action="" class="formulario">
            <p>Seleccione un periodo de busquedad</p>
            <input type="date" name="periodoFirst" id="pFirst" class="form form-control" [(ngModel)]="first" required="true" />
            <input type="date" name="periodoEnd" id="pEnd" class="form form-control"     [(ngModel)]="end" required="true" />
            <button type="button" role="button" name="" id="" class="btn " (click)="getConvocatoriasCAS()" [disabled]="!first"  >
              APLICAR BUSQUEDADs
            </button>
          </form>
        </div>
        
        <div class="container tabla " >
          <table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)"
           >
           <!--  matSort (matSortChange)="announceSortChange($event)" mat-sort-header -->
            <ng-container matColumnDef="idconvocatoriascas">
              <th mat-header-cell *matHeaderCellDef  >No.</th>
              <td mat-cell *matCellDef="let element">
                {{ element.idconvocatoriascas }}
              </td>
            </ng-container>
        
            <!-- Name Column -->
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> nombre</th>
              <td mat-cell *matCellDef="let element">{{ element.nombre.replaceAll('NÂ°','N°') }}</td>
            </ng-container>
        
            <!-- Weight Column -->
            <ng-container matColumnDef="periodo">
              <th class="w-max" mat-header-cell *matHeaderCellDef mat-sort-header >periodo</th>
              <td class="w-max" mat-cell *matCellDef="let element">{{ element.periodo }}</td>
            </ng-container>
        
            <!-- Symbol Column -->
            <ng-container matColumnDef="bases">
              <th class="w-max" mat-header-cell *matHeaderCellDef  >bases</th>
              <td class="w-max" mat-cell *matCellDef="let element">
                <span *ngIf="element.bases !=''  "><a href="http://www.munilambayeque.gob.pe/presentacion{{ element.bases }} " target="_blank"> <img src="assets/img/pdf-ico.png" alt=""></a></span>
              </td>
            </ng-container>
        
            <!-- Symbol Column -->
            <ng-container matColumnDef="fe_erratas">
              <th class="w-max" mat-header-cell *matHeaderCellDef>fe_erratas</th>
              <td class="w-max" mat-cell *matCellDef="let element">
                <span *ngIf="element.fe_erratas != '' "><a href="http://www.munilambayeque.gob.pe/presentacion{{ element.fe_erratas }}" target="_blank"> <img src="assets/img/pdf-ico.png" alt=""></a></span>
            </td>
            </ng-container>
        
            <!-- Symbol Column -->
            <ng-container matColumnDef="resultados">
              <th class="w-max" mat-header-cell *matHeaderCellDef>resultados</th>
              <td class="w-max" mat-cell *matCellDef="let element">
                <span *ngIf=" element.resultados != '' "><a href="http://www.munilambayeque.gob.pe/presentacion{{ element.resultados }}" target="_blank"> <img src="assets/img/pdf-ico.png" alt=""></a></span>
            </td>
            </ng-container>
        
            <!-- Symbol Column -->
            <ng-container matColumnDef="resultadosfinales">
              <th class="w-max"  mat-header-cell *matHeaderCellDef>resultadosfinales</th>
              <td class="w-max"  mat-cell *matCellDef="let element">
                <span *ngIf="element.resultadosfinales != ''"><a href="http://www.munilambayeque.gob.pe/presentacion{{ element.resultadosfinales }}" target="_blank"><img src="assets/img/pdf-ico.png" alt=""></a></span>
              </td>
            </ng-container>
        
            <!-- Symbol Column -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                         showFirstLastButtons
                         aria-label="Select page of periodic elements">
          </mat-paginator>
        </div>

        </div>
    </mat-tab>
  
    <mat-tab label="Convocatorias Proceso de Selección">
      <div class="">

        <app-convocatorias-g></app-convocatorias-g>
      </div>
    </mat-tab>

  </mat-tab-group>
  
</div>