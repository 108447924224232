import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';

const apiUrl = `${environment.other.apiUrl}`;
const headers = new HttpHeaders().set('Content-Type', 'application/json');
const options = { headers };
@Injectable({
  providedIn: 'root'
})
export class DocumentoServiceService {

  //url =`${apiUrl}convocatorias`; 
  private urlDocumentos = `${apiUrl}documentos`;
  constructor(private http:HttpClient) { }


  getDocumentos(): Observable<any>{
    return this.http.get<any>(`${this.urlDocumentos}/listar`,options);
  }

  getDirectivas(): Observable<any>{
    return this.http.get<any>(`${this.urlDocumentos}/directivas`,options);
  }

}
 